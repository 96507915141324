import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    zIndex: 10,
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    padding: 0,
  },

  toggle: {
    position: 'absolute',
    top: 160,
    right: 10,
    zIndex: 100,
  },

  iconButton: {
    textAlign: 'center',
    height: 26,
    minWidth: 'auto',
    lineHeight: '100%',
    padding: 5,
    '& > span': {
      fontWeight: 400,
    },
  },

  iconButtonWhite: {
    textAlign: 'center',
    height: 26,
    minWidth: 'auto',
    lineHeight: '100%',
    padding: 5,
    backgroundColor: theme.palette.common.white,
    '& > span': {
      fontWeight: 400,
    },
  },

  projectCreateContainer: {
    margin: theme.spacing(3, 5, 0, 3),
  },

  btnGroup: {
    boxShadow: 'none',
  },

  toolbarControls: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'start',
    minHeight: 90,
  },

  wideSelect: {
    width: 260,
  },

  link: {
    '& > span': {
      fontWeight: 500,
      fontSize: 14,

      textTransform: 'uppercase',
    },
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    marginLeft: 10,
    color: theme.palette.text.primary,
  },

  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    backgroundColor: 'transparent',
    marginRight: 15,
    cursor: 'pointer',
  },

  iconButtonRed: {
    color: theme.palette.primary.main,
    display: 'flex',
    '& > span': {
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    fontSize: 12,
    marginLeft: 10,
  },

  exportCaption: {
    fontFamily: ffSourceSansPro,
    fontWeight: 500,
    textTransform: 'uppercase',
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: 14,
    marginLeft: 10,
    color: theme.palette.text.primary,
  },

}));
