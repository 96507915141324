import React, { FC, Fragment } from 'react';
import { TreeItem } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';

import { VehicleType, AcRouteDirection } from 'src/cluster/common';
import {
  AcEditorPageForm, EditorMode, EditorTreeCaption,
} from 'src/cluster/editor-map';

import { RouteDirectionFooter, RouteDirectionHeader } from './components';

import {
  useCommonStyles, useListItemStyles, useLongNameStyles, useTreeItemStyles,
} from './styles';

interface OwnProps {
  pageForm: AcEditorPageForm;
  isOnlyDirection: boolean;
  direction: AcRouteDirection;
  variantId: number;
  vehicleTypeName?: VehicleType;
  setSelectedStop(id: number): void;
  addDirection(variantId: number): void;
  deleteDirection(variantId: number, directionId: number): void;
  swapDirections(variantId: number): void;
}

type Props = OwnProps & WithTranslate;

const RouteDirection: FC<Props> = (props) => {
  const {
    pageForm: { editorMode, directionId },
    isOnlyDirection,
    direction,
    variantId,
    vehicleTypeName,
    t,
    setSelectedStop,
    addDirection,
    deleteDirection,
    swapDirections,
  } = props;

  const {
    id, nodes, length, longName, direction: isForward,
  } = direction;
  const isSelected = directionId === id;

  const classes = useCommonStyles({ vehicleTypeName });
  const longNameClasses = useLongNameStyles();
  const treeItemClasses = useTreeItemStyles({ vehicleTypeName, isSelected });
  const stopClasses = useListItemStyles({ vehicleTypeName });

  const createDoubleClickHandler = (stopId: number | null) => () => {
    if (stopId) {
      setSelectedStop(Number(stopId));
    }
  };

  const handleAddDirection = () => {
    addDirection(variantId);
  };

  const handleDeleteDirection = () => {
    deleteDirection(variantId, id);
  };

  const handleSwapDirections = () => {
    swapDirections(variantId);
  };

  const isModifying = [EditorMode.addPoint, EditorMode.removePoint].includes(editorMode);

  return (
    <TreeItem
      nodeId={`direction@@${id}@@${variantId}`}
      classes={treeItemClasses}
      label={(
        <RouteDirectionHeader
          isModifying={isModifying}
          isForward={isForward}
          isOnlyDirection={isOnlyDirection}
          addDirection={handleAddDirection}
          deleteDirection={handleDeleteDirection}
          swapDirections={handleSwapDirections}
        />
      )}
    >
      {id > 0 && (
        <EditorTreeCaption
          label={t('systems.agglomeration.captions.routeDirectionTitle')}
          className={classes.directionTitle}
        />
      )}
      <Typography classes={longNameClasses}>{longName}</Typography>
      {isModifying && (
        <EditorTreeCaption
          label={t('modules.editor.captions.listOfObjects')}
          className={classes.listOfObjects}
        />
      )}
      {nodes.map((stop, index) => {
        if (!isModifying && !stop.stopId) return null;
        return (
          <TreeItem
            nodeId={`node@@${stop.nodeId}`}
            data-scroll-to={`node@@${stop.nodeId}`}
            key={`${stop.nodeId}${index}`}
            classes={stopClasses}
            onDoubleClick={createDoubleClickHandler(Number(stop.stopId))}
            label={(
              <Fragment>
                <span className={classes.count}>
                  {index + 1}
                </span>
                <span className={stop.stopId ? classes.greenCircle : classes.purpleCircle} />
                <span className={classes.name}>
                  {stop.stopId ? stop.stopName : t('modules.editor.legendNodes.referencePoint')}
                </span>
              </Fragment>
            )}
          />
        );
      })}
      {!isModifying && id > 0 && <RouteDirectionFooter length={length} id={id} />}
    </TreeItem>
  );
};

export default RouteDirection;
