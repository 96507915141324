import { createSelector } from 'reselect';

import { isNumber, serializeQuery } from 'src/helpers';

import { AC_MATRIX_FORECAST_ENDPOINT } from 'src/cluster/common';
import { selectMatrixForecastParams } from 'src/cluster/common/store/selectors';
import { selectAcProjectUrl } from 'src/cluster/projects';
import { selectDistGraphForm } from 'src/cluster/distribution-common';
import {
  DISTRIBUTION_EDGES_GEOJSON_ENDPOINT,
  DISTRIBUTION_STOPS_GEOJSON_ENDPOINT,
  DISTRIBUTION_EDGES_DAY_GEOJSON_ENDPOINT,
  DISTRIBUTION_STOPS_DAY_GEOJSON_ENDPOINT,
} from 'src/cluster/distribution-map';

export const selectDistributionGeojsonEndpoint = createSelector(
  [
    selectAcProjectUrl,
    selectDistGraphForm,
    selectMatrixForecastParams,
  ],
  (projectsUrl, graphForm, forecastParams): [string | undefined, string | undefined] => {
    if (!projectsUrl) {
      return [undefined, undefined];
    }

    const {
      scenarioId, year, intervalId, day,
    } = graphForm;
    const [forecastId] = forecastParams;

    if (
      !isNumber(forecastId)
      || !isNumber(scenarioId)
      || !isNumber(year)
      || (!isNumber(intervalId) && !day)
    ) {
      return [undefined, undefined];
    }

    const edgesUrl = `${projectsUrl}`
      + `${AC_MATRIX_FORECAST_ENDPOINT}${forecastId}/`
      + `${day ? DISTRIBUTION_EDGES_DAY_GEOJSON_ENDPOINT : DISTRIBUTION_EDGES_GEOJSON_ENDPOINT}`;

    const stopsUrl = `${projectsUrl}`
      + `${AC_MATRIX_FORECAST_ENDPOINT}${forecastId}/`
      + `${day ? DISTRIBUTION_STOPS_DAY_GEOJSON_ENDPOINT : DISTRIBUTION_STOPS_GEOJSON_ENDPOINT}`;

    const query = serializeQuery(intervalId && !day ? { scenarioId, year, intervalId } : { scenarioId, year });

    return [`${edgesUrl}?${query}`, `${stopsUrl}?${query}`];
  },
);
