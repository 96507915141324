import app from 'src/app';
import i18n from 'src/i18n';
import { ThunkActionResult } from 'src/reducer';

import { TrackPoint } from 'src/cluster/common';
import { entitiesEditorFormActions, editorFormActions } from 'src/cluster/editor-common';
import {
  Pathfinder,
  findPath,
  mapRouteWithDirection,
  selectEditorSelectedRoute,
  selectEditorSelectedDirection,
} from 'src/cluster/editor-map';

export function deletePoint(node: TrackPoint): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const findPathSegment = (firstPoint: number, lastPoint: number) => dispatch(findPath(firstPoint, lastPoint));

    const editableDirection = selectEditorSelectedDirection(state).orUndefined();
    if (!editableDirection) return;
    const pathfinder = new Pathfinder(editableDirection, findPathSegment);
    try {
      const newDirection = await pathfinder.deletePoint(node);
      const editableRoute = selectEditorSelectedRoute(state).orUndefined();
      if (!editableRoute) return;
      const newRoute = mapRouteWithDirection(editableRoute, newDirection);
      dispatch(entitiesEditorFormActions.changeField('editableRoute', newRoute));
    } catch (e) {
      console.error(e);
      dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.deletePointError')));
    }
  };
}

export function addPoint(newNode: TrackPoint, index?: number): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const findPathSegment = (firstPoint: number, lastPoint: number) => dispatch(findPath(firstPoint, lastPoint));

    const editableDirection = selectEditorSelectedDirection(state).orUndefined();
    if (!editableDirection) return;
    const pathfinder = new Pathfinder(editableDirection, findPathSegment);
    try {
      const newDirection = await pathfinder.addPoint(newNode, index);
      const editableRoute = selectEditorSelectedRoute(state).orUndefined();
      if (!editableRoute) return;
      const newRoute = mapRouteWithDirection(editableRoute, newDirection);
      dispatch(entitiesEditorFormActions.changeField('editableRoute', newRoute));
      dispatch(editorFormActions.changeField('nodeId', newNode.nodeId));
    } catch (e) {
      console.error(e);
      dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.addPointError')));
    }
  };
}
