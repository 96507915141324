import { LOGIN_ENDPOINT } from 'src/api-url-schema';

export interface AuthForm {
  username: string;
  password: string;
}

export default {
  endpoint: LOGIN_ENDPOINT,
  defaults: {
    username: '',
    password: '',
  },
  resetOnSubmit: true,
};
