import { createSelector } from 'reselect';

import {
  createEntitySelector, createFormSelector, isNumber, isUndefined,
} from 'src/helpers';

import {
  AC_MATRIX_FORECAST_INFO_MODEL_NAME,
  AC_PROJECT_LIST_FORM_NAME,
  AcBalanceDataModel,
  AcBalanceTrafficSumModel,
  AcProjectForm,
  selectMatrixForecastParams,
  AC_PROJECTS_MODEL_NAME,
  selectAcProjectListForm,
} from 'src/cluster/common';
import { selectAcTrafficSumEntity } from 'src/cluster/balance-common';
import {
  AC_BALANCE_MAP_PAGE_FORM_NAME, AC_DATA_MAP_MODEL_NAME, AcBalanceMapPageForm, getCorrespondencesFilter,
} from 'src/cluster/balance-map';

export const selectAcProjectPageForm = createFormSelector<AcProjectForm>(AC_PROJECT_LIST_FORM_NAME);
export const selectAcBalanceMapPageForm = createFormSelector<AcBalanceMapPageForm>(AC_BALANCE_MAP_PAGE_FORM_NAME);

export const selectAcDataEntity = createEntitySelector<AcBalanceDataModel>(AC_DATA_MAP_MODEL_NAME);

export const selectTotalTraffic = createSelector(
  [
    selectAcProjectListForm,
    selectAcBalanceMapPageForm,
    selectMatrixForecastParams,
    selectAcTrafficSumEntity,
  ],
  (
    projectListForm,
    pageBalanceMapForm,
    matrixForecastParams,
    trafficSumEntities,
  ): AcBalanceTrafficSumModel | undefined => {
    const { selectedProject } = projectListForm;
    const [forecastId] = matrixForecastParams;

    if (!isNumber(selectedProject) || !isNumber(forecastId)) return undefined;

    const query = getCorrespondencesFilter(pageBalanceMapForm);
    if (isUndefined(query)) return undefined;

    const totalTrafficApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      query,
    };

    const trafficSum = trafficSumEntities.getById('', totalTrafficApiConfig);

    return trafficSum.$loading ? undefined : trafficSum;
  },
);
