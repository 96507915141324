import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ffSourceSansPro } from 'src/theme/typography';
import { VehicleType, VEHICLE_TYPES_COLORS } from 'src/cluster/common';

export const useTreeItemStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      backgroundColor: theme.palette.grey[50],
      borderWidth: 0,
      borderColor: theme.palette.common.white,
      borderStyle: 'solid',

      '&:not(:last-of-type)': {
        borderBottomWidth: 1,
      },

      '&:hover > $content': {
        backgroundColor: 'transparent',
      },

      '&:focus > $content $label, &:hover > $content $label': {
        backgroundColor: 'transparent',
      },

      '&$selected > $content $label': {
        backgroundColor: 'transparent',
      },

      '&$selected > $content $label:hover': {
        backgroundColor: 'transparent',
      },

      '&$selected:focus > $content $label': {
        backgroundColor: 'transparent',
      },
    },

    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 32,
      height: 28,
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(1),
      marginRight: 0,

      '& svg': {
        width: 12,
        height: 12,
      },
    },

    group: {
      marginLeft: 0,
      backgroundColor: theme.palette.common.white,
    },

    content: ({ vehicleTypeName, isSelected }: {
      vehicleTypeName: VehicleType | undefined;
      isSelected: boolean;
    }) => ({
      position: 'relative',

      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0.2,
        backgroundColor: vehicleTypeName && isSelected
          ? VEHICLE_TYPES_COLORS[vehicleTypeName]
          : 'transparent',
      },
    }),

    label: {
      padding: theme.spacing(1, 4, 1, 2),
      width: 'calc(100% - 32px)',
    },

    selected: {},

    expanded: {
      '& $label .RouteDirection-direction': {
        color: theme.palette.common.black,
        textDecoration: 'none',
      },
    },
  });
});

export const useListItemStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      userSelect: 'none',
      '&$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&:hover > $content': {
        backgroundColor: 'transparent',
      },

      '&:focus > $content $label, &:hover > $content $label': {
        backgroundColor: 'transparent',
      },

      '&$selected:focus > $content $label': {
        backgroundColor: 'transparent',
      },
    },

    selected: {
      '&> $content $label': {
        paddingLeft: theme.spacing(5),
      },
      '&> $content $label::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0.2,
      },
    },

    content: {},
    iconContainer: {
      display: 'none',
    },

    label: ({ vehicleTypeName }: { vehicleTypeName: VehicleType | undefined }) => ({
      fontFamily: ffSourceSansPro,
      fontSize: 12,
      position: 'relative',
      paddingLeft: theme.spacing(10),

      '&::before': {
        backgroundColor: vehicleTypeName ? VEHICLE_TYPES_COLORS[vehicleTypeName] : '#cccccc',
      },

      '&:hover::before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        opacity: 0.2,
        backgroundColor: vehicleTypeName ? VEHICLE_TYPES_COLORS[vehicleTypeName] : '#cccccc',
      },
    }),
  });
});

export const useLongNameStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.common.black,
      paddingLeft: theme.spacing(10),
    },
    subtitle1: {
      fontSize: 10,
      lineHeight: '13px',
      fontStyle: 'italic',
      color: '#666666',
      margin: '10px 5px',
      paddingLeft: 0,
    },
  });
});

export const useCommonStyles = makeStyles((theme: Theme) => {
  return createStyles({
    directionTitle: {
      '&.MuiTypography-root': {
        paddingLeft: 40,
      },
    },

    listOfObjects: {
      '&.MuiTypography-root': {
        display: 'block',
        fontSize: 12,
        fontWeight: 'bold',
        padding: theme.spacing(4, 0, 2, 10),
      },
    },

    count: {
      display: 'inline-block',
      width: 16,
      fontSize: 8,
      verticalAlign: 'middle',
      lineHeight: '18px',
    },

    name: {
      display: 'inline-block',
      paddingLeft: theme.spacing(1),
      verticalAlign: 'middle',
      lineHeight: '18px',
    },

    greenCircle: ({ vehicleTypeName }: { vehicleTypeName: VehicleType | undefined }) => ({
      display: 'inline-block',
      width: 14,
      height: 14,
      borderRadius: '50%',
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: vehicleTypeName ? VEHICLE_TYPES_COLORS[vehicleTypeName] : '#ccccc',
      verticalAlign: 'middle',
    }),

    purpleCircle: ({ vehicleTypeName }: { vehicleTypeName: VehicleType | undefined }) => ({
      display: 'inline-block',
      width: 13,
      height: 13,
      borderRadius: '50%',
      backgroundColor: vehicleTypeName ? VEHICLE_TYPES_COLORS[vehicleTypeName] : '#ccccc',
      verticalAlign: 'middle',
    }),
  });
});
