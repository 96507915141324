import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },

  subTitle: {
    padding: theme.spacing(3, 0, 3, 0),
  },

  fields: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -10px',
    paddingBottom: '10px',
  },

  field: {
    padding: '0 10px 15px',
    flexBasis: '100%',
  },

  buttons: {
    display: 'flex',
    margin: '0',
    justifyContent: 'center',
  },

  button: {
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    margin: '0 10px',
  },

  slider: {
    minWidth: 250,
    '& > #fullRemote': {
      margin: '20px auto',
    },
  },
}));
