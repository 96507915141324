import { withTranslate } from 'src/i18n';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'src/reducer';
import modals from 'src/modals';

import {
  selectMatrixForecastParams,
  AC_DIST_GRAPH_VERSION_MODAL_NAME,
  AC_EXPORT_SELECTION_MODAL_NAME,
} from 'src/cluster/common';
import { selectDistGraphForm } from 'src/cluster/distribution-common';
import {
  exportRoutes,
  exportRoutesDay,
  exportStops,
  exportStopsDay,
  selectAcDistPageForm,
} from 'src/cluster/distribution-table';

import DistributionTableToolbarTitle from './DistributionTableToolbarTitle';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectAcDistPageForm(state),
  graphForm: selectDistGraphForm(state),
  matrixForecastParams: selectMatrixForecastParams(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  showModal: () => dispatch(modals.actions.showModal(true, AC_DIST_GRAPH_VERSION_MODAL_NAME)),
  exportRoutes: (isFull?: boolean) => dispatch(exportRoutes(isFull)),
  exportRoutesDay: (isFull?: boolean) => dispatch(exportRoutesDay(isFull)),
  exportStops: (isFull?: boolean) => dispatch(exportStops(isFull)),
  exportStopsDay: (isFull?: boolean) => dispatch(exportStopsDay(isFull)),
  showExportsModal: (exportData: Function) => {
    dispatch(modals.actions.showModal(true, AC_EXPORT_SELECTION_MODAL_NAME, { exportData }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DistributionTableToolbarTitle));
