import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    overflow: 'hidden',
    borderRadius: 3,
    background: 'rgba(155, 177, 183, 0.5)',
    padding: theme.spacing(0.5, 1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(51, 59, 70, 0.7)',
    fontSize: 12,
    lineHeight: 15,
    fontWeight: 400,
    maxHeight: 24,
  },
  percentValue: {
    marginLeft: 2,
    marginRight: 8,
    fontWeight: 600,
  },
  declineButton: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 15,
    textDecoration: 'none',
    padding: 0,
    '& > span': {
      textTransform: 'capitalize',
      fontWeight: 'normal',
    },
  },
}));
