import uuidV4 from 'uuid/v4';

import {
  RoutePermission,
  SHIPMENTS_ANALYTIC_PERMISSION,
  SHIPMENTS_USER_PERMISSION,
  MATRIX_USER_PERMISSION,
  MATRIX_ANALYTIC_PERMISSION,
  CITIES_USER_PERMISSION,
  CITIES_ANALYTIC_PERMISSION,
  TRAFFIC_USER_PERMISSION,
  TRAFFIC_ANALYTIC_PERMISSION,
  MTH_USER_PERMISSION,
  MTH_ANALYTIC_PERMISSION,
  VIDEO_USER_PERMISSION,

  AC_CAN_UPLOAD_PROJECT_FILE_PERMISSION,
  AC_EDITOR_PERMISSION,
  AC_BALANCE_PERMISSION,
  AC_DISTRIBUTION_PERMISSION,
  AC_DASHBOARD_PERMISSION,
  FREIGHT_USER_PERMISSION, FREIGHT_EDITOR_PERMISSION, FREIGHT_CAN_UPLOAD_PROJECT_FILE_PERMISSION,
} from './constants';

export const HEADER_SHOW = 'show';
export const HEADER_HIDE = 'hide';

interface UrlSchemaConfigEntry {
  header: string;
  url: string;
  pages: UrlSchemaConfig | {};
  alias?: string;
  roles?: string[];
  redirect?: string;
  permissions?: RoutePermission;
}

type UrlSchemaConfig = Record<string, UrlSchemaConfigEntry>

type UrlSchemaEntry<T extends UrlSchemaConfig | {}> = T extends UrlSchemaConfig
  ? UrlSchema<T>
  : any

type UrlSchema<T extends UrlSchemaConfig> = {
  [key in keyof T]: {
    [pageKey in keyof T[key]['pages']]: UrlSchemaEntry<T[key]['pages']>;
  } & {
    $uuid: string;
  };
}

const urlSchema = {
  freight: {
    header: HEADER_SHOW,
    url: 'freight',
    permissions: [
      FREIGHT_USER_PERMISSION,
      FREIGHT_CAN_UPLOAD_PROJECT_FILE_PERMISSION,
    ],
    pages: {
      infrastructure: {
        header: HEADER_SHOW,
        url: 'infrastructure',
        permissions: [FREIGHT_EDITOR_PERMISSION],
      },
    },
  },
  shipments: {
    header: HEADER_SHOW,
    url: 'shipments',
    permissions: [SHIPMENTS_USER_PERMISSION, SHIPMENTS_ANALYTIC_PERMISSION],
    pages: {
      projects: {
        header: HEADER_SHOW,
        permissions: [SHIPMENTS_ANALYTIC_PERMISSION],
        url: 'projects',
      },
      calculations: {
        header: HEADER_SHOW,
        permissions: [SHIPMENTS_USER_PERMISSION, SHIPMENTS_ANALYTIC_PERMISSION],
        url: 'calculations',
      },
      routeAnalysis: {
        header: HEADER_SHOW,
        permissions: [SHIPMENTS_USER_PERMISSION, SHIPMENTS_ANALYTIC_PERMISSION],
        url: 'route-analysis',
      },
    },
  },
  agglomeration: {
    header: HEADER_SHOW,
    url: 'agglomeration',
    permissions: [
      AC_CAN_UPLOAD_PROJECT_FILE_PERMISSION,
      AC_EDITOR_PERMISSION,
      AC_BALANCE_PERMISSION,
    ],
    pages: {
      projects: {
        header: HEADER_SHOW,
        permissions: [AC_CAN_UPLOAD_PROJECT_FILE_PERMISSION],
        url: 'projects',
      },
      editor: {
        header: HEADER_SHOW,
        permissions: [AC_EDITOR_PERMISSION],
        url: 'editor',
        pages: {
          editor: {
            header: HEADER_SHOW,
            permissions: [AC_EDITOR_PERMISSION],
            url: 'editor',
          },
          registries: {
            header: HEADER_SHOW,
            permissions: [AC_EDITOR_PERMISSION],
            url: 'registries',
          },
        },
      },
      balance: {
        header: HEADER_SHOW,
        permissions: [AC_BALANCE_PERMISSION],
        url: 'balance',
        pages: {
          data: {
            header: HEADER_SHOW,
            permissions: [AC_BALANCE_PERMISSION],
            url: 'data',
          },
          ttc: {
            header: HEADER_SHOW,
            permissions: [AC_BALANCE_PERMISSION],
            url: 'ttc',
          },
          forecast: {
            header: HEADER_SHOW,
            permissions: [AC_BALANCE_PERMISSION],
            url: 'forecast',
          },
          map: {
            header: HEADER_SHOW,
            permissions: [AC_BALANCE_PERMISSION],
            url: 'map',
          },
        },
      },
      distribution: {
        header: HEADER_SHOW,
        permissions: [AC_DISTRIBUTION_PERMISSION],
        url: 'distribution',
        pages: {
          map: {
            header: HEADER_SHOW,
            permissions: [AC_DISTRIBUTION_PERMISSION],
            url: 'map',
          },
          table: {
            header: HEADER_SHOW,
            permissions: [AC_DISTRIBUTION_PERMISSION],
            url: 'table',
          },
        },
      },
      dashboard: {
        header: HEADER_SHOW,
        permissions: [AC_DASHBOARD_PERMISSION],
        url: 'dashboard',
        pages: {
          table: {
            header: HEADER_SHOW,
            permissions: [AC_DASHBOARD_PERMISSION],
            url: 'table',
          },
        },
      },
    },
  },
  video: {
    header: HEADER_SHOW,
    url: 'video',
    permissions: [VIDEO_USER_PERMISSION],
    pages: {
      video: {
        header: HEADER_SHOW,
        permissions: [VIDEO_USER_PERMISSION],
        url: 'video',
      },
    },
  },
  matrix: {
    header: HEADER_SHOW,
    url: 'matrix',
    permissions: [MATRIX_USER_PERMISSION, MATRIX_ANALYTIC_PERMISSION],
    pages: {
      projects: {
        header: HEADER_SHOW,
        permissions: [MATRIX_ANALYTIC_PERMISSION],
        url: 'projects',
      },
      macroeconomics: {
        header: HEADER_SHOW,
        permissions: [MATRIX_USER_PERMISSION, MATRIX_ANALYTIC_PERMISSION],
        url: 'macroeconomics',
      },
      transport: {
        header: HEADER_SHOW,
        permissions: [MATRIX_USER_PERMISSION, MATRIX_ANALYTIC_PERMISSION],
        url: 'transport',
      },
      forecast: {
        header: HEADER_SHOW,
        permissions: [MATRIX_USER_PERMISSION, MATRIX_ANALYTIC_PERMISSION],
        url: 'forecast',
      },
    },
  },
  mth: {
    header: HEADER_SHOW,
    url: 'mth',
    permissions: [MTH_USER_PERMISSION, MTH_ANALYTIC_PERMISSION],
    pages: {
      projects: {
        header: HEADER_SHOW,
        permissions: [MTH_ANALYTIC_PERMISSION],
        url: 'projects',
      },
      calculations: {
        header: HEADER_SHOW,
        permissions: [MTH_USER_PERMISSION, MTH_ANALYTIC_PERMISSION],
        url: 'calculations',
      },
      routeAnalysis: {
        header: HEADER_SHOW,
        permissions: [MTH_USER_PERMISSION, MTH_ANALYTIC_PERMISSION],
        url: 'route-analysis',
      },
      indicators: {
        header: HEADER_SHOW,
        permissions: [MTH_USER_PERMISSION, MTH_ANALYTIC_PERMISSION],
        url: 'indicators',
      },
    },
  },
  traffic: {
    header: HEADER_SHOW,
    url: 'traffic',
    permissions: [TRAFFIC_USER_PERMISSION, TRAFFIC_ANALYTIC_PERMISSION],
    pages: {
      projects: {
        header: HEADER_SHOW,
        permissions: [TRAFFIC_ANALYTIC_PERMISSION],
        url: 'projects',
      },
      map: {
        header: HEADER_SHOW,
        permissions: [TRAFFIC_USER_PERMISSION, TRAFFIC_ANALYTIC_PERMISSION],
        url: 'map',
      },
    },
  },
  cities: {
    header: HEADER_SHOW,
    url: 'cities',
    permissions: [CITIES_USER_PERMISSION, CITIES_ANALYTIC_PERMISSION],
    pages: {
      projects: {
        header: HEADER_SHOW,
        permissions: [CITIES_ANALYTIC_PERMISSION],
        url: 'projects',
      },
      calculations: {
        header: HEADER_SHOW,
        permissions: [CITIES_USER_PERMISSION, CITIES_ANALYTIC_PERMISSION],
        url: 'calculations',
      },
      routeAnalysis: {
        header: HEADER_SHOW,
        permissions: [CITIES_USER_PERMISSION, CITIES_ANALYTIC_PERMISSION],
        url: 'route-analysis',
      },
    },
  },
};

const paramsRegex = /:\w+(?=\/|$)/g;

// Different urls properties to find by uuid
const uniqueUrls: {
  [key: string]: any;
} = {};

const aliases: {
  [key: string]: any;
} = {};

const basePathsUrls: {
  [key: string]: any;
} = {};

const fullPathsUrls: {
  [key: string]: any;
} = {};

const noParamsUrls: {
  [key: string]: any;
} = {};

const baseUrls: {
  [key: string]: any;
} = {};

const permissionsForUrl: {
  [key: string]: any;
} = {};

const redirectForUrl: {
  [key: string]: any;
} = {};

const rootLevel: {
  [key: string]: any;
} = {};

function createSchemaMapObject<T extends UrlSchemaConfig>(
  schema: T,
  baseUrl = '',
  basePath: any[] = [],
): UrlSchema<T> {
  return Object.keys(schema).reduce((memo, key) => {
    const currentSchema = schema[key];
    const currentUrl = `${baseUrl}${currentSchema.url && '/'}${currentSchema.url}`;
    const newUuid = uuidV4();

    uniqueUrls[newUuid] = currentUrl;
    noParamsUrls[newUuid] = currentUrl.replace(paramsRegex, '');
    basePathsUrls[newUuid] = basePath;
    fullPathsUrls[newUuid] = basePath.concat(key);
    baseUrls[newUuid] = currentSchema.url;
    aliases[newUuid] = currentSchema.alias || key;
    permissionsForUrl[newUuid] = currentSchema.roles;
    redirectForUrl[newUuid] = currentSchema.redirect;
    rootLevel[newUuid] = basePath.length;

    const newBasePath = basePath.concat(key);

    return {
      ...memo,
      [key]: {
        $uuid: newUuid,
        ...(currentSchema.pages ? createSchemaMapObject(currentSchema.pages, currentUrl, newBasePath) : {}),
      },
    };
  }, {} as any);
}
const urlSchemaMap = createSchemaMapObject(urlSchema);

const getFromUrlsObject = (obj: any) => (urlMapObject: any, config: any = {}) => {
  let result = obj[urlMapObject.$uuid || urlMapObject];
  Object.keys(config).forEach(key => {
    result = result.replace(`:${key}`, config[key]);
  });
  return result;
};

export const getUrl = getFromUrlsObject(uniqueUrls);
export const getBaseUrl = getFromUrlsObject(baseUrls);

export const urlSchemaConfig = urlSchema;

export default urlSchemaMap;
