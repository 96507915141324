import { api, forms } from 'redux-restify';

import { ThunkActionResult } from 'src/reducer';
import { Query, GeojsonResponse } from 'src/types';
import app from 'src/app';
import i18n from 'src/i18n';

import {
  createFormActions, createModelActions, featureCollection, isArray, isNumber, objectToCamel,
} from 'src/helpers';

import {
  EDITOR_PAGE_FORM_NAME,
  EDITOR_GRAPH_FORM_NAME,
  EDITOR_COPY_NETWORK_FORM_NAME,
  AC_MATRIX_FORECAST_INFO_MODEL_NAME,
  AC_ROUTES_VARIANTS_MODEL_NAME,
  AC_DISTRIBUTION_PAGE_FORM_NAME,
  AC_DISTRIBUTION_MAP_PAGE_FORM_NAME,
  AC_YEARS_MODEL_NAME,
  AC_GEOJSON_FORM_NAME,
  AC_ROUTES_MODEL_NAME,
  AC_VEHICLE_TYPES_MODEL_NAME,
  AC_SCENARIOS_MODEL_NAME,
  AC_INTERVALS_MODEL_NAME,
  AC_COMMUNICATION_TYPES_MODEL_NAME,
  AC_REGULAR_TRANSPORTATION_TYPES_MODEL_NAME,
  AC_REGISTRY_CARRIERS_MODEL_NAME,
  AC_REGISTRY_ROUTES_MODEL_NAME,
  AC_REGISTRY_SEASONS_MODEL_NAME,
  AC_REGISTRY_FILTERS_NAME,
  AC_REGISTRY_PAGE_FORM_NAME,
  AC_MAP_LEGEND_FORM_NAME,
  TTC_REGIONS_MODEL_NAME,
  TTC_SITES_MODEL_NAME,
  TTC_CALC_PAGE_FORM_NAME,
  AC_FORECAST_CORRESPONDENCES_MODEL_NAME,
  AC_FORECAST_TRAFFIC_SUM_MODEL_NAME,
  AC_DATA_FACTORS_MODEL_NAME,
  AC_DATA_REGION_TYPES_MODEL_NAME,
  AC_DATA_MODEL_NAME,
  AC_FORECAST_PAGE_FORM_NAME,
  AC_DATA_PAGE_FORM_NAME,
  AC_DASH_INDICATOR_RESULT_MODEL_NAME,
  AC_DASH_CATEGORIES_MODEL_NAME,
  AC_DIST_DIRECTION_EDGES_MODEL_NAME,
  AC_STOPS_MODEL_NAME,
  selectAcProjectListForm,
  AcDistPageForm,
  AcGeojsonForm,
  AcEditorLegendForm,
  AcRoutesModel,
  AcStopsModel,
  AcDistributionExtreme,
  getApiConfigWithProject,
} from 'src/cluster/common';
import { AcDistributionMapPageForm } from 'src/cluster/distribution-map';

export const geojsonActions = createFormActions<AcGeojsonForm>(AC_GEOJSON_FORM_NAME);
export const editorLegendActions = createFormActions<AcEditorLegendForm>(AC_MAP_LEGEND_FORM_NAME);
export const distPageFormActions = createFormActions<AcDistPageForm>(AC_DISTRIBUTION_PAGE_FORM_NAME);
export const distMapFormActions = createFormActions<AcDistributionMapPageForm>(AC_DISTRIBUTION_MAP_PAGE_FORM_NAME);
export const matrixForecastEntityManager = createModelActions(AC_MATRIX_FORECAST_INFO_MODEL_NAME);
export const routesEntityManager = createModelActions<AcRoutesModel>(AC_ROUTES_MODEL_NAME);
export const stopEntityManager = createModelActions<AcStopsModel>(AC_STOPS_MODEL_NAME);
export const routesVariantsEntityManager = createModelActions(AC_ROUTES_VARIANTS_MODEL_NAME);

export function clearProjectData(): ThunkActionResult<any> {
  return (dispatch) => {
    dispatch(api.actions.entityManager[AC_YEARS_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_ROUTES_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_VEHICLE_TYPES_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_SCENARIOS_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_INTERVALS_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[TTC_REGIONS_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[TTC_SITES_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_COMMUNICATION_TYPES_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_REGISTRY_CARRIERS_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_REGISTRY_ROUTES_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_REGISTRY_SEASONS_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_REGULAR_TRANSPORTATION_TYPES_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_MATRIX_FORECAST_INFO_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_FORECAST_CORRESPONDENCES_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_FORECAST_TRAFFIC_SUM_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_DATA_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_DATA_FACTORS_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_DATA_REGION_TYPES_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_DASH_INDICATOR_RESULT_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_DASH_CATEGORIES_MODEL_NAME].clearData());
    dispatch(api.actions.entityManager[AC_DIST_DIRECTION_EDGES_MODEL_NAME].clearData());

    dispatch(forms.actions[AC_DATA_PAGE_FORM_NAME].resetForm());
    dispatch(forms.actions[TTC_CALC_PAGE_FORM_NAME].resetForm());
    dispatch(forms.actions[AC_FORECAST_PAGE_FORM_NAME].resetForm());
    dispatch(forms.actions[EDITOR_GRAPH_FORM_NAME].resetForm());
    dispatch(forms.actions[EDITOR_COPY_NETWORK_FORM_NAME].resetForm());
    dispatch(forms.actions[EDITOR_PAGE_FORM_NAME].resetForm());
    dispatch(forms.actions[AC_GEOJSON_FORM_NAME].resetForm());
    dispatch(forms.actions[AC_REGISTRY_FILTERS_NAME].resetForm());
    dispatch(forms.actions[AC_REGISTRY_PAGE_FORM_NAME].resetForm());
    dispatch(forms.actions[AC_FORECAST_PAGE_FORM_NAME].resetForm());
    dispatch(forms.actions[AC_DISTRIBUTION_PAGE_FORM_NAME].resetForm());
    dispatch(forms.actions[AC_DISTRIBUTION_MAP_PAGE_FORM_NAME].resetForm());
  };
}

export function loadMatrixForecast(): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState());
    if (!isNumber(selectedProject)) {
      return;
    }

    const apiConfig = getApiConfigWithProject(selectedProject, { pageSize: 1 });

    dispatch(matrixForecastEntityManager.loadData(apiConfig));
  };
}

export function fetchGeojson<T>(url: string, query: Query): ThunkActionResult<GeojsonResponse<T>> {
  return async (dispatch) => {
    const apiConfig = { url, query };
    try {
      const { data, status }: any = await dispatch(api.actions.callGet(apiConfig));
      if (status >= 400) {
        dispatch(app.actions.toast.error(i18n.t('modules.editor.messages.geojsonError')));
        return featureCollection([]);
      }

      const { type, features } = data;

      if (type !== 'FeatureCollection' || !isArray(features)) {
        dispatch(app.actions.toast.error(i18n.t('modules.editor.messages.geojsonError')));
        return featureCollection([]);
      }

      if (features.length === 0) {
        dispatch(app.actions.toast.warning(i18n.t('modules.editor.messages.geojsonWarning')));
        return featureCollection([]);
      }

      return objectToCamel(data);
    } catch (err) {
      dispatch(app.actions.toast.error(i18n.t('modules.editor.messages.geojsonError')));
      console.error(err);
      return featureCollection([]);
    }
  };
}

export function fetchExtreme(url: string, query: Query): ThunkActionResult<Promise<AcDistributionExtreme>> {
  return async (dispatch) => {
    const apiConfig = { url, query };
    try {
      const { data, status }: any = await dispatch(api.actions.callGet(apiConfig));
      if (status >= 400) {
        return { minimum: 0, maximum: 0 };
      }

      return objectToCamel(data);
    } catch (err) {
      console.error(err);
      return { minimum: 0, maximum: 0 };
    }
  };
}
