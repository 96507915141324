import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      height: 56,
      width: '100%',
    },

    link: {},

    url: {
      display: 'flex',
      transition: 'none',
      height: '100%',
      width: '100%',
      color: theme.palette.common.white,

      '&:hover': {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.text.primary,
        '& img': {
          filter: 'invert(1)',
        },
      },

      '& div, & a': {
        transition: 'none',
      },

      '&[href="/agglomeration"]': {
        '& span': {
          display: 'none',
        },
      },
    },

    active: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
    },

    linkContent: {
      display: 'flex',
      height: '100%',
      width: '100%',
      padding: theme.spacing(0, 5),
      fontSize: 14,
      alignItems: 'center',
    },

    icon: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 22,
      height: 22,
      marginRight: 10,
      flex: '0 0 auto',
    },
  });
});
