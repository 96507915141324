import { TRAFFIC_SUM_ENDPOINT } from 'src/cluster/balance-forecast';
import { AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: TRAFFIC_SUM_ENDPOINT,
  parent: [AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME],
  idField: () => 1,
  defaults: {
    trafficBaseYearPrivate: undefined,
    trafficBaseYearPublic: undefined,
    trafficBaseYearTotal: undefined,
    trafficForecastPrivate: undefined,
    trafficForecastPublic: undefined,
    trafficForecastTotal: undefined,
    trafficIncrease: undefined,
  },
  clearPagesOnRouteChange: false,
  pagination: false,
};
