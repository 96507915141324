import React, { ChangeEvent, FC, useState } from 'react';
import {
  Radio, RadioGroup, FormControlLabel, Typography,
} from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { Button } from 'src/components';
import { ExportTypes } from 'src/cluster/common';

import useStyles from './styles';

interface Props extends WithTranslate {
  exportData(isFull?: boolean): void;
  close: () => void;
}

const ModalExportSelection: FC<Props> = (props) => {
  const { t, close, exportData } = props;

  const classes = useStyles();
  const [exportType, setExportType] = useState<ExportTypes>(ExportTypes.full);

  const handleExport = () => {
    exportData(exportType === ExportTypes.full);
    close();
  };

  const handleChange = (event: ChangeEvent<{}>, value: string) => {
    setExportType(value as ExportTypes);
  };
  return (
    <div className={classes.root}>
      <Typography variant="h5">{t('components.captions.exportTypeSelecting')}</Typography>
      <RadioGroup
        className={classes.radioGroup}
        name="exportType"
        value={exportType}
        onChange={handleChange}
      >
        <FormControlLabel
          value={ExportTypes.full}
          control={<Radio color="primary" size="small" />}
          label={`${t('components.captions.fullExport')}`}
        />
        <FormControlLabel
          value={ExportTypes.filtered}
          control={<Radio color="primary" size="small" />}
          label={`${t('components.captions.filteredExport')}`}
        />
      </RadioGroup>
      <div className={classes.buttons}>
        <Button
          className={classes.button}
          onClick={handleExport}
        >
          {t('common.captions.export')}
        </Button>
      </div>
    </div>
  );
};

export default ModalExportSelection;
