import { createSelector } from 'reselect';
import { Maybe, Just, Nothing } from 'monet';

import { Model } from 'src/interfaces';
import {
  isArray, createFormSelector, createEntitySelector, createLoadsManager,
} from 'src/helpers';

import {
  AC_PROJECTS_MODEL_NAME,
  AC_PROJECTS_ENDPOINT,
  selectProjectEndpoint,
  selectAcProjectListForm,
} from 'src/cluster/common';
import {
  AC_PROJECT_CREATE_FORM_NAME,
  AC_UPLOADING_FILE_ENDPOINT,
  AcProjectCreateForm,
  AcProjectsModel,
  isAcProjectsModel,
} from 'src/cluster/projects';

export const selectAcProjectCreateForm = createFormSelector<AcProjectCreateForm>(AC_PROJECT_CREATE_FORM_NAME);

export const selectProjectEntities = createEntitySelector<AcProjectsModel>(AC_PROJECTS_MODEL_NAME);

export const fileUploadsManager = createLoadsManager(AC_UPLOADING_FILE_ENDPOINT);

export const projectLoadsManager = createLoadsManager(AC_PROJECTS_ENDPOINT);

export const selectAcProjects = createSelector(
  selectAcProjectListForm,
  selectProjectEntities,
  (projectListForm, projectEntities): Maybe<Model<AcProjectsModel>[]> => {
    const { pageSize, currentPage, loadStatus } = projectListForm;

    const apiConfig = {
      filter: {
        page: currentPage,
        pageSize,
        loadStatus,
      },
    };

    const projects = projectEntities.getArray(apiConfig);

    if (!isArray(projects)) {
      return Nothing();
    }

    if (projects.length > 0 && !isAcProjectsModel(projects[0])) {
      return Nothing();
    }

    return Just(projects);
  },
);

export const selectAcProjectsCount = createSelector(
  selectAcProjectListForm,
  selectProjectEntities,
  (projectListForm, projectEntities): number => {
    const { pageSize, currentPage, loadStatus } = projectListForm;

    const apiConfig = {
      filter: {
        page: currentPage,
        pageSize,
        loadStatus,
      },
    };

    return projectEntities.getCount(apiConfig);
  },
);

export const selectAcProjectUrl = createSelector(
  [
    selectAcProjectListForm,
    selectProjectEndpoint,
  ],
  (projectListForm, projectEntities): string | undefined => {
    const { apiHost, apiPrefix, endpoint } = projectEntities;
    if (!projectListForm.selectedProject) {
      return undefined;
    }
    const projectEndpoint = `${apiHost}${apiPrefix}${endpoint}`;

    return `${projectEndpoint}${projectListForm.selectedProject}/`;
  },
);
