import { api } from 'redux-restify';

import { ThunkActionResult } from 'src/reducer';
import modals from 'src/modals';
import app from 'src/app';
import i18n from 'src/i18n/i18n';
import { RequestStatus } from 'src/types';
import {
  createFormActions, createModelActions, downloadFile, getFilename, isNumber,
} from 'src/helpers';
import { BinaryApiResponse } from 'src/interfaces';

import {
  CopyingType,
  AcEditorCopyNetworkForm,
  AcEditorGraphForm,
  isAcCopyRequestsModel,
  selectCopyRequestEntities,
  selectAcProjectListForm,
  routesEntityManager,
  stopEntityManager,
  geojsonActions,
  AC_PROJECTS_ENDPOINT,
  EDITOR_COPY_NETWORK_FORM_NAME,
  EDITOR_GRAPH_FORM_NAME,
  ENTITIES_EDITOR_FORM_NAME,
  EDITOR_PAGE_FORM_NAME,
  AC_REGISTRY_PAGE_FORM_NAME,
  AC_REGISTRY_FILTERS_NAME,
  AC_REGISTRY_ROUTES_MODEL_NAME,
  AC_COMMUNICATION_TYPES_MODEL_NAME,
  AC_REGISTRY_CARRIERS_MODEL_NAME,
  AC_REGISTRY_SEASONS_MODEL_NAME,
  AC_REGULAR_TRANSPORTATION_TYPES_MODEL_NAME,
} from 'src/cluster/common';
import { copyRequestsModelActions } from 'src/cluster/projects';
import {
  ExportType,
  AC_EDITOR_EXPORT_NETWORK_MODAL_NAME,
  AC_EDITOR_COPY_NETWORK_MODAL_NAME,
  selectEditorCopyNetworkForm,
  EXPORT_TYPE_URLS_DICT,
  selectEditorGraphForm,
} from 'src/cluster/editor-common';
import { AcEditorPageForm, AcEntitiesEditorForm } from 'src/cluster/editor-map';
import { AcRegistryFiltersForm, AcRegistryPageForm } from 'src/cluster/editor-table';

/** Editor actions */
export const editorFormActions = createFormActions<AcEditorPageForm>(EDITOR_PAGE_FORM_NAME);
export const graphFormActions = createFormActions<AcEditorGraphForm>(EDITOR_GRAPH_FORM_NAME);
export const entitiesEditorFormActions = createFormActions<AcEntitiesEditorForm>(ENTITIES_EDITOR_FORM_NAME);
export const copyingNetworkFormActions = createFormActions<AcEditorCopyNetworkForm>(EDITOR_COPY_NETWORK_FORM_NAME);
/** Registry actions */
export const registryPageFormActions = createFormActions<AcRegistryPageForm>(AC_REGISTRY_PAGE_FORM_NAME);
export const registryFiltersActions = createFormActions<AcRegistryFiltersForm>(AC_REGISTRY_FILTERS_NAME);
/** Registry managers */
export const routesRegEntityManager = createModelActions(AC_REGISTRY_ROUTES_MODEL_NAME);
export const communicationRegEntityManager = createModelActions(AC_COMMUNICATION_TYPES_MODEL_NAME);
export const carriersRegEntityManager = createModelActions(AC_REGISTRY_CARRIERS_MODEL_NAME);
export const seasonsRegEntityManager = createModelActions(AC_REGISTRY_SEASONS_MODEL_NAME);
export const transportationRegEntityManager = createModelActions(AC_REGULAR_TRANSPORTATION_TYPES_MODEL_NAME);

export function startExportingNetwork(): ThunkActionResult<any> {
  return (dispatch) => {
    dispatch(modals.actions.showModal(true, AC_EDITOR_EXPORT_NETWORK_MODAL_NAME));
  };
}

export function completeExportingNetwork(type: ExportType): ThunkActionResult<any> {
  return async (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState());
    if (!isNumber(selectedProject)) {
      return Promise.reject();
    }

    const config = {
      url: `${AC_PROJECTS_ENDPOINT}${selectedProject}/${EXPORT_TYPE_URLS_DICT[type]}`,
      isBinary: true,
    };

    dispatch(editorFormActions.changeField('isLoading', true));
    try {
      const { data, status, api: xhr }: BinaryApiResponse = await dispatch(api.actions.callGet(config));
      if (status === 200 && data !== undefined) {
        const blob = new Blob([data], { type: 'octet/stream' });
        downloadFile(blob, `${getFilename(xhr)}`);
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      dispatch(editorFormActions.resetField('isLoading'));
    }
  };
}

export function startCopyingNetwork(): ThunkActionResult<any> {
  return (dispatch) => {
    dispatch(modals.actions.showModal(true, AC_EDITOR_COPY_NETWORK_MODAL_NAME));
  };
}

function updateCopyRequestsStatus(id: number): ThunkActionResult<any> {
  return async (dispatch, getState) => {
    const timer = setInterval(async () => {
      const record = await dispatch(copyRequestsModelActions.loadById(id));

      if (!isAcCopyRequestsModel(record)) {
        dispatch(copyingNetworkFormActions.changeField('isError', true));
        return;
      }

      const currentState = getState();
      const { requestId } = selectEditorCopyNetworkForm(currentState);

      if (requestId !== record.id) {
        clearInterval(timer);
      }

      if (record.status === RequestStatus.AWAITING_CONFIRMATION) {
        dispatch(copyingNetworkFormActions.changeField('isCopying', false));
        dispatch(copyingNetworkFormActions.changeField('isAwaitingConfirmation', true));
        dispatch(copyingNetworkFormActions.changeField('directionIds', record.ignoredMap.routeDirection));
        clearInterval(timer);
      }

      if (record.status === RequestStatus.SUCCESS) {
        dispatch(copyingNetworkFormActions.changeField('isCopying', false));
        dispatch(copyingNetworkFormActions.changeField('isSuccess', true));
        clearInterval(timer);
      }

      if (record.status === RequestStatus.ERROR) {
        dispatch(copyingNetworkFormActions.changeField('isCopying', false));
        dispatch(copyingNetworkFormActions.changeField('isError', true));
        clearInterval(timer);
      }
    },
    5000);
  };
}

export function doCopyingNetwork(): ThunkActionResult<any> {
  return async (dispatch, getState) => {
    const state = getState();
    const { year: originYear, scenarioId: originScenarioId } = selectEditorGraphForm(state);
    const { year: targetYear, scenarioId: targetScenarioId } = selectEditorCopyNetworkForm(state);

    if (!isNumber(originYear) || !isNumber(originScenarioId) || !isNumber(targetYear) || !isNumber(targetScenarioId)) {
      dispatch(copyingNetworkFormActions.changeField('isError', true));
      return;
    }

    dispatch(copyingNetworkFormActions.changeField('isCopying', true));
    try {
      const { status, data } = await dispatch(copyRequestsModelActions.create({
        type: CopyingType.network,
        inputData: {
          originYear,
          originScenarioId,
          targetYear,
          targetScenarioId,
        },
      }));

      if (status !== 201 || !isAcCopyRequestsModel(data)) {
        dispatch(copyingNetworkFormActions.changeField('isError', true));
        return;
      }

      dispatch(copyingNetworkFormActions.changeField('requestId', data.id));
      dispatch(updateCopyRequestsStatus(data.id));
    } catch (e) {
      dispatch(app.actions.toast.error(i18n.t('systems.editor.messages.copyNetworkError')));
      console.error(e);
    }
  };
}

export function completeCopyingNetwork(): ThunkActionResult<any> {
  return (dispatch) => {
    dispatch(modals.actions.showModal(false, AC_EDITOR_COPY_NETWORK_MODAL_NAME));
    dispatch(copyingNetworkFormActions.resetForm());
  };
}

export function cancelCopyingNetwork(): ThunkActionResult<any> {
  return async (dispatch, getState) => {
    const state = getState();
    const { requestId } = selectEditorCopyNetworkForm(state);
    const copyRequestEntities = selectCopyRequestEntities(state);
    try {
      if (!isNumber(requestId)) {
        console.error(`Wrong a request ID: ${requestId}`);
        dispatch(copyingNetworkFormActions.changeField('isError', true));
        return;
      }

      const values = copyRequestEntities.getById(requestId);
      const { status } = await dispatch(copyRequestsModelActions.action(requestId, 'cancel', values));
      if (status !== 200) {
        console.error('Failed to cancel copying the route network');
        dispatch(copyingNetworkFormActions.changeField('isError', true));
      }
    } catch (e) {
      dispatch(app.actions.toast.error(i18n.t('systems.editor.messages.copyNetworkError')));
      console.error(e);
    } finally {
      dispatch(completeCopyingNetwork());
    }
  };
}

export function confirmCopyingNetwork(): ThunkActionResult<any> {
  return async (dispatch, getState) => {
    const state = getState();
    const { requestId } = selectEditorCopyNetworkForm(state);
    const copyRequestEntities = selectCopyRequestEntities(state);
    try {
      if (!isNumber(requestId)) {
        console.error(`Wrong a request ID: ${requestId}`);
        dispatch(copyingNetworkFormActions.changeField('isError', true));
        return;
      }

      const values = copyRequestEntities.getById(requestId);
      const { status, data } = await dispatch(copyRequestsModelActions.action(requestId, 'confirm', values));
      if (status !== 200) {
        console.error('Failed to confirm copying the route network');
        dispatch(copyingNetworkFormActions.changeField('isError', true));
        return;
      }
      dispatch(copyingNetworkFormActions.changeField('isAwaitingConfirmation', false));

      if (data.status === RequestStatus.IN_PROGRESS_CONFIRMED) {
        dispatch(copyingNetworkFormActions.changeField('isCopying', true));
        dispatch(updateCopyRequestsStatus(requestId));
      }
      if (data.status === RequestStatus.SUCCESS) {
        dispatch(copyingNetworkFormActions.changeField('isSuccess', true));
      }
    } catch (e) {
      dispatch(app.actions.toast.error(i18n.t('systems.editor.messages.copyNetworkError')));
      console.error(e);
    }
  };
}

export function resetEditorGraph(intervalChanging?: boolean, resetAll?: boolean): ThunkActionResult<void> {
  return (dispatch) => {
    dispatch(
      resetAll
        ? editorFormActions.resetForm()
        : editorFormActions.resetSomeFields([
          'editorMode',
          'message',
          'routeId',
          'directionId',
          'variantId',
          'nodeId',
          'stopId',
          'edgeId',
        ]));
    dispatch(routesEntityManager.clearData());
    if (intervalChanging) {
      dispatch(geojsonActions.resetField('edges'));
    } else {
      dispatch(geojsonActions.resetForm());
    }
  };
}

export function clearRegistryPage(): ThunkActionResult<any> {
  return (dispatch) => {
    dispatch(routesRegEntityManager.clearData());
    dispatch(communicationRegEntityManager.clearData());
    dispatch(carriersRegEntityManager.clearData());
    dispatch(seasonsRegEntityManager.clearData());
    dispatch(transportationRegEntityManager.clearData());

    dispatch(registryFiltersActions.resetForm());
    dispatch(registryPageFormActions.resetForm());
    dispatch(registryPageFormActions.changeField('registryId', 1)); // select routes registry by default
  };
}

export function switchToNewRouteNetwork(): ThunkActionResult<any> {
  return async (dispatch, getState) => {
    const state = getState();
    const { year, scenarioId } = selectEditorCopyNetworkForm(state);
    dispatch(graphFormActions.changeSomeFields({ year, scenarioId }));
    dispatch(resetEditorGraph(false, true));
    dispatch(entitiesEditorFormActions.resetForm());
    dispatch(stopEntityManager.clearData());
    dispatch(clearRegistryPage());
    dispatch(completeCopyingNetwork());
  };
}
