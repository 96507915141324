import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import { Editable, Model } from 'src/interfaces';
import { isArray, isNumber } from 'src/helpers';

import { AC_PROJECTS_MODEL_NAME, selectVehicleTypes, selectAcProjectListForm } from 'src/cluster/common';
import { selectEditorGraphForm } from 'src/cluster/editor-common';
import {
  AcEdgeSpeed,
  AcEdgeSpeedChange,
  EditorMode,
  isAcEdgeSpeed,
  isAcEdgeSpeedChange,
} from 'src/cluster/editor-map';

import {
  selectEditorPageForm,
  selectEntitiesEditorForm,
  selectEdgeSpeedEntities,
  selectEdgeSpeedChangeEntities,
} from './common';

export const selectEditorSelectedEdge = createSelector(
  [
    selectAcProjectListForm,
    selectEditorGraphForm,
    selectEditorPageForm,
  ],
  (projectForm, graphForm, pageForm): Maybe<Model<{ id: number }>> => {
    const { selectedProject } = projectForm;
    const { intervalId } = graphForm;
    const { edgeId } = pageForm;
    if (
      !isNumber(selectedProject)
        || !isNumber(edgeId)
        || !isNumber(intervalId)
    ) {
      return Nothing();
    }

    return Just({
      $loading: false,
      $error: false,
      $old: false,
      $modelType: 'acEdge',
      id: edgeId,
    });
  },
);

export const selectEditorEdgeSpeeds = createSelector(
  [
    selectAcProjectListForm,
    selectEditorPageForm,
    selectEditorGraphForm,
    selectEntitiesEditorForm,
    selectEdgeSpeedEntities,
  ],
  (projectForm, pageForm, graphForm, editorForm, edgeSpeedsEntities): Maybe<Editable<Model<AcEdgeSpeed>>[]> => {
    const { selectedProject } = projectForm;
    const { edgeId, editorMode } = pageForm;
    const { intervalId } = graphForm;
    const { editableEdgeSpeeds } = editorForm;
    if (
      !isNumber(selectedProject)
      || !isNumber(edgeId)
      || !isNumber(intervalId)
    ) {
      return Nothing();
    }

    if (editorMode === EditorMode.modifyEdge && editableEdgeSpeeds && isAcEdgeSpeed(editableEdgeSpeeds[0])) {
      return Just(editableEdgeSpeeds);
    }

    const edgeSpeedApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        intervalId,
        edgeId,
      },
    };

    const edgeSpeeds = edgeSpeedsEntities.getArray(edgeSpeedApiConfig);
    const isLoading = edgeSpeedsEntities.getIsLoadingArray(edgeSpeedApiConfig);

    if (isLoading || !isArray(edgeSpeeds)) {
      return Nothing();
    }

    if (edgeSpeeds.length > 0 && !isAcEdgeSpeed(edgeSpeeds[0])) {
      return Nothing();
    }

    return Just(edgeSpeeds);
  },
);

export const selectEditorEdgeSpeedChanges = createSelector(
  [
    selectAcProjectListForm,
    selectEditorPageForm,
    selectEditorGraphForm,
    selectEntitiesEditorForm,
    selectVehicleTypes,
    selectEdgeSpeedChangeEntities,
  ],
  (...args): Maybe<Editable<Model<AcEdgeSpeedChange>>[]> => {
    const [
      projectForm,
      pageForm,
      graphForm,
      editorForm,
      vehicleTypes,
      speedChangesEntities,
    ] = args;
    const { selectedProject } = projectForm;
    const { editorMode } = pageForm;
    const { intervalId, scenarioId, year } = graphForm;
    const { editableEdgeSpeedChanges } = editorForm;
    if (
      !isNumber(selectedProject)
      || !isNumber(intervalId)
      || editorMode !== EditorMode.graph
    ) {
      return Nothing();
    }

    if (editableEdgeSpeedChanges && isAcEdgeSpeedChange(editableEdgeSpeedChanges[0])) {
      return Just(editableEdgeSpeedChanges);
    }

    const edgeSpeedApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        intervalId,
        scenarioId,
        year,
      },
    };

    const speedChanges = speedChangesEntities.getArray(edgeSpeedApiConfig);
    const isLoading = speedChangesEntities.getIsLoadingArray(edgeSpeedApiConfig);

    if (isLoading || !isArray(speedChanges)) {
      return Nothing();
    }

    if (speedChanges.length > 0 && !isAcEdgeSpeedChange(speedChanges[0])) {
      return Nothing();
    }

    return vehicleTypes.map((types) => {
      const vehicleTypeIds = types.map(type => type.id);
      return speedChanges.filter((change) => {
        return vehicleTypeIds.includes(change.vehicleTypeId);
      });
    });
  },
);
