import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'src/reducer';

import { editorFormActions } from 'src/cluster/editor-common';
import { exportVariant, selectEditorPageForm } from 'src/cluster/editor-map';

import EditorRouteTreeView from './EditorRouteTreeView';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectEditorPageForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setSelectedNode: (id: number) => {
    dispatch(editorFormActions.changeField('nodeId', id));
  },
  setSelectedDirection: (id: number, variantId: number) => {
    dispatch(editorFormActions.resetField('nodeId'));
    dispatch(editorFormActions.changeField('directionId', id));
    dispatch(editorFormActions.changeField('variantId', variantId));
  },
  resetSelectedDirection: () => {
    dispatch(editorFormActions.resetField('directionId'));
    dispatch(editorFormActions.resetField('variantId'));
  },
  onExportVariant: (id: number) => dispatch(exportVariant(id)),
  onSelect: () => {
    dispatch(editorFormActions.changeField('canBePositioned', true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorRouteTreeView);
