import { api } from 'redux-restify';
import { ApiCallConfig } from 'redux-restify/src/api/adapters';

import {
  createFormActions,
  createModelActions,
  downloadFile,
  getFilename,
  isNumber,
} from 'src/helpers';
import modals from 'src/modals';
import { BinaryApiResponse } from 'src/interfaces';
import { ThunkActionResult } from 'src/reducer';

import {
  AC_MATRIX_FORECAST_ENDPOINT,
  AC_ROUTES_VARIANTS_MODEL_NAME,
  AC_DISTRIBUTION_PAGE_FORM_NAME,
  AC_DIST_GRAPH_VERSION_MODAL_NAME,
  AC_PROJECTS_ENDPOINT,
  AC_DIST_DIRECTION_EDGES_MODEL_NAME,
  AcDistPageForm,
  selectMatrixForecastParams,
  selectAcProjectListForm,
} from 'src/cluster/common';
import { selectDistGraphForm } from 'src/cluster/distribution-common';
import {
  DISTRIBUTION_ROUTES_DAY_ENDPOINT,
  DISTRIBUTION_ROUTES_ENDPOINT,
  DISTRIBUTION_STOPS_DAY_ENDPOINT,
  DISTRIBUTION_STOPS_ENDPOINT,
  selectAcDistPageForm,
} from 'src/cluster/distribution-table';

export const distPageFormActions = createFormActions<AcDistPageForm>(AC_DISTRIBUTION_PAGE_FORM_NAME);
export const routesVariantsEntityManager = createModelActions(AC_ROUTES_VARIANTS_MODEL_NAME);
export const distDirectionEdgesEntityManager = createModelActions(AC_DIST_DIRECTION_EDGES_MODEL_NAME);

export function initDistributionTable(): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const {
      year, scenarioId, intervalId, day,
    } = selectDistGraphForm(getState());

    if (!year || !scenarioId || !(day || intervalId)) {
      dispatch(modals.actions.showModal(true, AC_DIST_GRAPH_VERSION_MODAL_NAME));
    }
  };
}

function doExportDistribution(config: ApiCallConfig & { url: string }): ThunkActionResult<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch(distPageFormActions.changeField('isExporting', true));
      const { data, status, api: xhr }: BinaryApiResponse = await dispatch(api.actions.callGet(config));
      if (status === 200 && data !== undefined) {
        const blob = new Blob([data], { type: 'octet/stream' });
        downloadFile(blob, getFilename(xhr));
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      dispatch(distPageFormActions.resetField('isExporting'));
    }
  };
}

export function exportRoutes(isFull?: boolean): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const [forecastId] = selectMatrixForecastParams(state);
    const { year, scenarioId, intervalId } = selectDistGraphForm(state);
    const { vehicleTypeId, routeVariantId } = selectAcDistPageForm(state);
    if (
      !isNumber(selectedProject)
      || !isNumber(forecastId)
      || isFull && (
        !isNumber(year)
        || !isNumber(scenarioId)
        || !isNumber(intervalId)
      )
    ) {
      return Promise.reject();
    }
    const projectUrl = `${AC_PROJECTS_ENDPOINT}${selectedProject}`;
    const url = `${projectUrl}/${AC_MATRIX_FORECAST_ENDPOINT}${forecastId}/${DISTRIBUTION_ROUTES_ENDPOINT}export/`;
    const query = isFull ? {} : {
      year,
      scenarioId,
      intervalId,
      vehicleTypeId,
      routeVariantId,
    };

    return dispatch(doExportDistribution({ url, isBinary: true, query }));
  };
}

export function exportRoutesDay(isFull?: boolean): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const [forecastId] = selectMatrixForecastParams(state);
    const { year, scenarioId } = selectDistGraphForm(state);
    const { vehicleTypeId, routeVariantId } = selectAcDistPageForm(state);
    if (
      !isNumber(selectedProject)
      || !isNumber(forecastId)
      || isFull && (!isNumber(year) || !isNumber(scenarioId))
    ) {
      return Promise.reject();
    }
    const projectUrl = `${AC_PROJECTS_ENDPOINT}${selectedProject}`;
    const url = `${projectUrl}/${AC_MATRIX_FORECAST_ENDPOINT}${forecastId}/${DISTRIBUTION_ROUTES_DAY_ENDPOINT}export/`;
    const query = isFull ? {} : {
      year,
      scenarioId,
      vehicleTypeId,
      routeVariantId,
    };

    return dispatch(doExportDistribution({ url, isBinary: true, query }));
  };
}

export function exportStops(isFull?: boolean): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const [forecastId] = selectMatrixForecastParams(state);
    const { year, scenarioId, intervalId } = selectDistGraphForm(state);
    const { vehicleTypeIdStop: vehicleTypeId, stopId } = selectAcDistPageForm(state);
    if (
      !isNumber(selectedProject)
      || !isNumber(forecastId)
      || isFull && (
        !isNumber(year)
        || !isNumber(scenarioId)
        || !isNumber(intervalId)
      )
    ) {
      return Promise.reject();
    }
    const projectUrl = `${AC_PROJECTS_ENDPOINT}${selectedProject}`;
    const url = `${projectUrl}/${AC_MATRIX_FORECAST_ENDPOINT}${forecastId}/${DISTRIBUTION_STOPS_ENDPOINT}export/`;
    const query = isFull ? {} : {
      year,
      scenarioId,
      intervalId,
      vehicleTypeId,
      stopId,
    };

    return dispatch(doExportDistribution({ url, isBinary: true, query }));
  };
}

export function exportStopsDay(isFull?: boolean): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const [forecastId] = selectMatrixForecastParams(state);
    const { year, scenarioId } = selectDistGraphForm(state);
    const { vehicleTypeIdStop: vehicleTypeId, stopId } = selectAcDistPageForm(state);
    if (
      !isNumber(selectedProject)
      || !isNumber(forecastId)
      || isFull && (!isNumber(year) || !isNumber(scenarioId))
    ) {
      return Promise.reject();
    }
    const projectUrl = `${AC_PROJECTS_ENDPOINT}${selectedProject}`;
    const url = `${projectUrl}/${AC_MATRIX_FORECAST_ENDPOINT}${forecastId}/${DISTRIBUTION_STOPS_DAY_ENDPOINT}export/`;
    const query = isFull ? {} : {
      year,
      scenarioId,
      vehicleTypeId,
      stopId,
    };

    return dispatch(doExportDistribution({ url, isBinary: true, query }));
  };
}
