import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import { Editable, Model } from 'src/interfaces';
import { isNumber } from 'src/helpers';

import {
  AcStopsModel, isAcStopModel, AC_PROJECTS_MODEL_NAME, selectAcProjectListForm,
} from 'src/cluster/common';
import { selectEditorGraphForm } from 'src/cluster/editor-common';
import { EMPTY_STOP } from 'src/cluster/editor-map';

import {
  selectEditorPageForm,
  selectEntitiesEditorForm,
  selectStopEntities,
} from './common';

export const selectEditorStops = createSelector(
  [
    selectAcProjectListForm,
    selectEditorGraphForm,
    selectStopEntities,
  ],
  (projectForm, graphForm, stopEntities): Maybe<Model<AcStopsModel>[]> => {
    const { selectedProject } = projectForm;
    const { scenarioId, year } = graphForm;

    if (
      !isNumber(selectedProject)
      || !isNumber(year)
      || !isNumber(scenarioId)
    ) {
      return Nothing();
    }

    const stopApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        scenarioId,
        year,
      },
    };

    const stops = stopEntities.getArray(stopApiConfig);
    const isLoading = stopEntities.getIsLoadingArray(stopApiConfig);

    if (isLoading || !isAcStopModel(stops[0])) {
      return Nothing();
    }

    return Just(stops);
  },
);

export const selectEditorSelectedStop = createSelector(
  [
    selectAcProjectListForm,
    selectEditorPageForm,
    selectEntitiesEditorForm,
    selectStopEntities,
  ],
  (projectForm, pageForm, editorForm, stopEntities): Maybe<Editable<Model<AcStopsModel>>> => {
    const { selectedProject } = projectForm;
    const { stopId } = pageForm;
    const { editableStop } = editorForm;
    if (!isNumber(selectedProject) || !isNumber(stopId)) {
      return Nothing();
    }

    if (editableStop && isAcStopModel(editableStop)) {
      return Just(editableStop);
    }

    if (stopId === -1) {
      return Just(EMPTY_STOP);
    }

    const stopApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    const stop = stopEntities.getById(stopId, stopApiConfig);
    // const isLoading = stopEntities.getIsLoadingById(stopId, stopApiConfig);
    const isLoading = stop.$loading;

    if (isLoading || !isAcStopModel(stop)) {
      return Nothing();
    }

    return Just(stop);
  },
);
