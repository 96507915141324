import app from 'src/app';
import i18n from 'src/i18n/i18n';
import { ThunkActionResult } from 'src/reducer';
import { createModelActions } from 'src/helpers';

import {
  AC_PROJECTS_MODEL_NAME,
  selectAcProjectListForm,
  isAcRoutesModel,
  stopEntityManager,
} from 'src/cluster/common';
import { selectEditorGraphForm, entitiesEditorFormActions, editorFormActions } from 'src/cluster/editor-common';
import {
  AC_REGISTRY_ROUTES_MODEL_NAME,
  selectEditorSelectedRoute,
  selectEntitiesEditorForm,
  routeEntityManager,
  enableViewMode,
  refreshGraph,
} from 'src/cluster/editor-map';

export const registryRoutesEntityManager = createModelActions(AC_REGISTRY_ROUTES_MODEL_NAME);

export function changeRouteNumber(value: string): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const { editableRoute } = selectEntitiesEditorForm(state);
    if (!editableRoute) {
      return;
    }

    const updatedRoute = {
      ...editableRoute,
      routeNumber: value,
    };

    dispatch(entitiesEditorFormActions.changeField('editableRoute', updatedRoute));
    dispatch(entitiesEditorFormActions.setFieldDirtyState('editableRoute', true));
  };
}

export function copyRoute(): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const { editableRoute } = selectEntitiesEditorForm(state);
    if (!editableRoute) {
      return;
    }

    const routeNumber = `${i18n.t('systems.editor.captions.copy')} ${editableRoute.routeNumber}`;

    const newRoute = {
      ...editableRoute,
      id: -1,
      routeNumber,
    };

    dispatch(entitiesEditorFormActions.changeField('editableRoute', newRoute));
    dispatch(entitiesEditorFormActions.setFieldDirtyState('editableRoute', true));
  };
}

export function createRoute(): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const { year, scenarioId } = selectEditorGraphForm(state);
    const selectedRoute = selectEditorSelectedRoute(state);

    return selectedRoute.cata(
      () => {
        dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.createRouteError')));
        console.error(new Error('The route cannot be undefined.'));
      },
      async (route) => {
        const values = {
          ...route,
          id: undefined,
          scenarioId,
          year,

          // by default
          tariff: 30,
          tariffId: 1,
        };

        const routeApiConfig = {
          parentEntities: {
            [AC_PROJECTS_MODEL_NAME]: selectedProject,
          },
        };

        try {
          dispatch(editorFormActions.changeField('isLoading', true));
          const { data, status } = await dispatch(routeEntityManager.create(values, routeApiConfig));
          if (status >= 400) {
            dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.createRouteError')));
            return;
          }

          dispatch(refreshGraph());

          dispatch(editorFormActions.changeField('routeId', data.id));
          dispatch(registryRoutesEntityManager.clearData());
        } catch (err) {
          dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.createRouteError')));
        } finally {
          await dispatch(enableViewMode());
          dispatch(editorFormActions.resetField('isLoading'));
          dispatch(stopEntityManager.clearData());
        }
      },
    );
  };
}

export function deleteRoute(id: number): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);

    dispatch(editorFormActions.changeField('isLoading', true));
    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    try {
      const { status } = await dispatch(routeEntityManager.delete(id, routeApiConfig));
      if (status >= 400) {
        dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.deleteRouteError', { id })));
        return;
      }

      dispatch(refreshGraph(true));

      dispatch(editorFormActions.resetSomeFields([
        'editorMode',
        'message',
        'routeId',
        'directionId',
        'variantId',
      ]));
      dispatch(entitiesEditorFormActions.resetForm());
      dispatch(registryRoutesEntityManager.clearData());
    } catch (err) {
      dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.deleteRouteError', { id })));
    } finally {
      dispatch(editorFormActions.resetField('isLoading'));
      dispatch(stopEntityManager.clearData());
    }
  };
}

export function updateRoute(): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const { editableRoute } = selectEntitiesEditorForm(state);

    if (!editableRoute) {
      return;
    }

    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    try {
      dispatch(editorFormActions.changeField('isLoading', true));
      const { data } = await dispatch(routeEntityManager.patch(editableRoute.id, editableRoute, routeApiConfig));
      if (!isAcRoutesModel(data)) {
        dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.updateRouteError', { id: editableRoute.id })));
        return;
      }

      dispatch(refreshGraph());

      dispatch(editorFormActions.resetFields(['editorMode', 'message']));
      dispatch(entitiesEditorFormActions.resetForm());
      dispatch(registryRoutesEntityManager.clearData());
    } catch (err) {
      dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.updateRouteError', { id: editableRoute.id })));
    } finally {
      dispatch(editorFormActions.resetField('isLoading'));
      dispatch(stopEntityManager.clearData());
    }
  };
}
