import React, { ChangeEvent, FC } from 'react';
import clsx from 'clsx';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { MatrixForecastProgress } from 'src/cluster/common';
import {
  ViewModes,
  AcBalanceMapPageForm,
  BalanceMapDataFilter,
  BalanceMapForecastFilter,
} from 'src/cluster/balance-map';

import useStyles from './styles';

export interface Props extends WithTranslate {
  className?: string;
  pageForm: AcBalanceMapPageForm;
  setViewMode(event: ChangeEvent<HTMLInputElement>, value: string): void;
}

const BalanceMapSidebar: FC<Props> = (props) => {
  const {
    t,
    className,
    pageForm,
    pageForm: { viewMode },
    setViewMode,
  } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <MatrixForecastProgress />
      <RadioGroup
        className={classes.radioGroup}
        name="viewMode"
        value={viewMode}
        onChange={setViewMode}
      >
        <FormControlLabel
          value={ViewModes.data}
          control={<Radio color="primary" size="small" />}
          label={`${t('systems.balance.captions.sociallyEconomicData')}`}
        />
        <FormControlLabel
          value={ViewModes.forecast}
          control={<Radio color="primary" size="small" />}
          label={`${t('systems.balance.captions.passengerFlows')}`}
        />
      </RadioGroup>
      {viewMode === ViewModes.data && (
        <BalanceMapDataFilter
          pageForm={pageForm}
        />
      )}
      {viewMode === ViewModes.forecast && (
        <BalanceMapForecastFilter
          pageForm={pageForm}
        />
      )}
    </div>
  );
};

export default BalanceMapSidebar;
