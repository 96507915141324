import { useDispatch, useSelector } from 'react-redux';
import type { PickInfo } from '@deck.gl/core/lib/deck';

import { Feature } from 'src/interfaces';
import {
  featureCollection, isNumber, multiLineString, point,
} from 'src/helpers';

import { PathSegment, TrackPoint } from 'src/cluster/common';
import { editorFormActions, selectEditorGraphForm } from 'src/cluster/editor-common';
import {
  EditorMode,
  DirectionLayer,
  selectEditorSelectedDirection,
  selectEditorPageForm,
  setSelectedStop,
  deletePoint,
  useVehicleColor,
  AC_EDITOR_SELECT_NODE_MODAL,
} from 'src/cluster/editor-map';
import modals from 'src/modals';
import i18n from 'src/i18n/i18n';

const useDirectionLayer = () => {
  const dispatch = useDispatch();

  const selectEdge = (edgeId: number) => {
    if (isNumber(edgeId)) {
      dispatch(editorFormActions.changeField('edgeId', edgeId));
    }
  };

  const selectNode = (nodeId: number) => {
    if (isNumber(nodeId)) {
      dispatch(editorFormActions.changeField('nodeId', nodeId));
    }
  };

  const selectStop = (stopId: number, order: number) => {
    if (isNumber(stopId)) {
      dispatch(setSelectedStop(stopId, order));
    }
  };

  const removePoint = (node: TrackPoint) => {
    if (isNumber(node?.nodeId)) {
      dispatch(deletePoint(node));
    }
  };

  const {
    editorMode,
    vehicleTypeId,
    nodeId: selectedNodeId,
    stopId: selectedStopId,
    isWaiting,
  } = useSelector(selectEditorPageForm);
  const { intervalId } = useSelector(selectEditorGraphForm);
  const direction = useSelector(selectEditorSelectedDirection).orUndefined();

  const edges = direction
    ? direction.path.flatMap((segment: PathSegment) => segment.edges.map(edge => multiLineString(edge.geometry, {
      edgeId: edge.id,
    })))
    : [];

  const points = direction
    ? direction.nodes.map((node: TrackPoint) => point(node.coordinates, {
      nodeId: node.nodeId,
      stopId: node.stopId,
      order: node.order,
    }))
    : [];

  const edgesData = featureCollection(edges);
  const pointsData = featureCollection(points);

  const [color] = useVehicleColor(vehicleTypeId);

  const selectedPointIndexes = selectedNodeId
    ? [pointsData.features.findIndex((feature) => Number(feature.properties?.nodeId) === Number(selectedNodeId))]
    : [];

  const editable = editorMode === EditorMode.addPoint || editorMode === EditorMode.removePoint;

  const handleClick = ({ object }: PickInfo<Feature>, { tapCount }: any) => {
    dispatch(editorFormActions.changeField('canBePositioned', false));
    const {
      nodeId, stopId, order, edgeId,
    } = object.properties || {};

    if (edgeId && !editable && intervalId) {
      selectEdge(edgeId);
      return;
    }

    if (tapCount > 1) {
      selectStop(stopId, order);
      return;
    }

    if (editorMode === EditorMode.removePoint) {
      const nodes = direction?.nodes.filter((node) => node.nodeId === nodeId) || [];
      if (nodes.length > 1) {
        dispatch(modals.actions.showModal(true, AC_EDITOR_SELECT_NODE_MODAL, {
          title: i18n.t('modules.editor.captions.modalSelectTitle'),
          nodes,
        }));
      } else {
        removePoint(nodes[0]);
      }

      return;
    }

    selectNode(nodeId);

    if (stopId && selectedStopId) {
      selectStop(stopId, order);
    }
  };

  return new DirectionLayer({
    id: 'direction',
    pointsData,
    edgesData,
    visible: true,
    pickable: !isWaiting,
    editable,
    color,
    selectedNodeId,
    selectedStopId,
    selectedPointIndexes,
    onClick: handleClick,
  });
};

export default useDirectionLayer;
