import React, { FC, useState } from 'react';

import {
  Toolbar,
  ToolbarBlock,
  ToolbarControl,
  ToolbarControls,
  Button,
  Autocomplete,
  SelectField,
  CollapseWithToggle,
  CircularProgressWrapper,
  ExcelIcon,
  GearIcon,
  PenIcon,
} from 'src/components';
import {
  useCallOnChange,
  BalanceFilterModes,
  MatrixForecastProgress,
} from 'src/cluster/common';
import { useDataToolbarFields } from 'src/cluster/balance-data';

import { Props } from './interfaces';

import useStyles from './styles';

const DataToolbar: FC<Props> = (props) => {
  const {
    t,
    pageForm,
    isSavable,
    saveAll,
    showForecastModal,
    showExportsModal,
    switchToEdit,
    switchToList,
    resetPageForm,
    onChangeField,
  } = props;

  const classes = useStyles();

  useCallOnChange(resetPageForm);

  const { isEdit, filterMode, isExporting } = pageForm;
  const config = useDataToolbarFields(pageForm, onChangeField);

  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => setCollapsed(value => !value);

  return (
    <div className={classes.projectCreateContainer}>
      <div className={classes.titleWrapper} >
        <span className={classes.title}>
          {t('modules.data.captions.pageTitle')}
        </span>
        <div className={classes.buttonsGroup}>
          <span>
            <Button
              variant="contained"
              startIcon={<GearIcon />}
              onClick={showForecastModal}
              className={classes.btnWithIcon}
            >
              {t('modules.forecast.captions.buildForecast')}
            </Button>
          </span>

          <span>
            <Button
              startIcon={
                <span className={classes.iconButtonRed}>
                  <PenIcon />
                </span>
              }
              className={classes.link}
              variant="text"
              onClick={switchToEdit}
            >
              {t('common.captions.edit')}
            </Button>
          </span>
          <CircularProgressWrapper loading={isExporting}>
            <Button
              startIcon={
                <span className={classes.iconButtonRed}>
                  <ExcelIcon />
                </span>
              }
              disabled={isExporting}
              className={classes.link}
              variant="text"
              onClick={showExportsModal}
            >
              {t('common.captions.export')}
            </Button>
          </CircularProgressWrapper>

        </div>
      </div>

      <CollapseWithToggle
        collapsed={collapsed}
        toggle={toggle}
        progress={<MatrixForecastProgress />}
        caption={t('common.captions.filter')}
      >
        <Toolbar className={classes.root}>
          <ToolbarBlock className={classes.block}>
            <ToolbarControls className={classes.toolbarControls} position="top">
              <ToolbarControl>
                <SelectField
                  label={t(config.year.label)}
                  options={config.year.items}
                  value={config.year.value}
                  fullWidth
                  onChange={config.year.command}
                />
              </ToolbarControl>

              <ToolbarControl>
                <Autocomplete
                  className={classes.tightSelect}
                  placeholder={t('components.captions.select')}
                  multiple={config.scenarioId.multiple}
                  label={t(config.scenarioId.label)}
                  selected={config.scenarioId.selected}
                  options={config.scenarioId.items}
                  hideClear
                  onChange={config.scenarioId.command}
                />
              </ToolbarControl>

              <ToolbarControl>
                <Autocomplete
                  className={classes.select}
                  placeholder={t('components.captions.select')}
                  multiple={config.intervalId.multiple}
                  label={t(config.intervalId.label)}
                  selected={config.intervalId.selected}
                  options={config.intervalId.items}
                  hideClear
                  sort={false}
                  onChange={config.intervalId.command}
                />
              </ToolbarControl>

              <ToolbarControl>
                <SelectField
                  label={t(config.filterMode.label)}
                  className={classes.wideSelect}
                  options={config.filterMode.items}
                  value={config.filterMode.value}
                  onChange={config.filterMode.command}
                />
              </ToolbarControl>

              <ToolbarControl className={classes.toolbarControl}>
                {filterMode === BalanceFilterModes.node ? (
                  <Autocomplete
                    multiple={config.regions.multiple}
                    label={t(config.regions.label)}
                    placeholder={t('components.captions.select')}
                    limitTags={8}
                    options={config.regions.items}
                    selected={config.regions.selected}
                    className={classes.tightSelectGrow}
                    onChange={config.regions.command}
                  />
                ) : (
                  <Autocomplete
                    multiple={config.municipalities.multiple}
                    label={t(config.municipalities.label)}
                    placeholder={t('components.captions.select')}
                    limitTags={8}
                    options={config.municipalities.items}
                    selected={config.municipalities.selected}
                    className={classes.tightSelectGrow}
                    onChange={config.municipalities.command}
                  />
                )}
              </ToolbarControl>

              <ToolbarControl>
                <Autocomplete
                  placeholder={t('components.captions.select')}
                  multiple={config.regionType.multiple}
                  label={t(config.regionType.label)}
                  selected={config.regionType.selected}
                  options={config.regionType.items}
                  onChange={config.regionType.command}
                  className={classes.wideSelect}
                />
              </ToolbarControl>
            </ToolbarControls>
          </ToolbarBlock>
        </Toolbar>
      </CollapseWithToggle>

      {isEdit ?
        (
          <ToolbarBlock>
            <ToolbarControls
              align="right"
              position="bottom"
              className={classes.toolBarControlsLine}
            >
              <ToolbarControl className={classes.buttonControls}>
                <Button
                  className={classes.button}
                  variant="outlined"
                  disabled={!isSavable}
                  onClick={saveAll}
                >
                  {t('common.captions.save')}
                </Button>

                <Button
                  className={classes.cancelLink}
                  variant="text"
                  onClick={switchToList}
                >
                  {t('common.captions.cancel')}
                </Button>
              </ToolbarControl>
            </ToolbarControls>
          </ToolbarBlock>
        )
        :
        null}
    </div>

  );
};

export default DataToolbar;
