import { createSelector } from 'reselect';
import { Maybe, Just, Nothing } from 'monet';

import { Model } from 'src/interfaces';
import {
  createEntitySelector,
  isArray,
  isNumber,
  isUndefined,
} from 'src/helpers';
import {
  AC_MATRIX_FORECAST_INFO_MODEL_NAME,
  AC_PROJECTS_MODEL_NAME,
  AC_FORECAST_CORRESPONDENCES_MODEL_NAME,
  AC_FORECAST_TRAFFIC_SUM_MODEL_NAME,
  AC_SCENARIOS_MODEL_NAME,
  AcBalanceScenariosModel,
  AcBalanceTrafficSumModel,
  AcForecastCorrespondenceModel,
  isAcForecastCorrespondenceModel,
  isAcScenariosModel,
  selectAcProjectListForm,
  selectMatrixForecastParams,
} from 'src/cluster/common';

import { selectAcForecastPageForm } from 'src/cluster/balance-common';

import { getCorrespondencesFilter } from 'src/cluster/balance-forecast';

export const selectAcCorrespondencesEntity =
  createEntitySelector<AcForecastCorrespondenceModel>(AC_FORECAST_CORRESPONDENCES_MODEL_NAME);
export const selectAcTrafficSumEntity =
  createEntitySelector<AcBalanceTrafficSumModel>(AC_FORECAST_TRAFFIC_SUM_MODEL_NAME);
export const selectAcForecastScenariosEntity = createEntitySelector<AcBalanceScenariosModel>(AC_SCENARIOS_MODEL_NAME);

export const selectTotalTraffic = createSelector(
  [
    selectAcProjectListForm,
    selectAcForecastPageForm,
    selectMatrixForecastParams,
    selectAcTrafficSumEntity,
  ],
  (
    projectListForm,
    pageForecastForm,
    matrixForecastParams,
    trafficSumEntities,
  ): AcBalanceTrafficSumModel | undefined => {
    const { selectedProject } = projectListForm;
    const [forecastId] = matrixForecastParams;

    if (!isNumber(selectedProject) || !isNumber(forecastId)) return undefined;

    const query = getCorrespondencesFilter(pageForecastForm);
    if (isUndefined(query)) return undefined;

    const totalTrafficApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      query,
    };

    const trafficSum = trafficSumEntities.getById('', totalTrafficApiConfig);

    return trafficSum.$loading ? undefined : trafficSum;
  },
);

export const selectSelectedForecastCorrespondences = createSelector(
  selectAcProjectListForm,
  selectAcForecastPageForm,
  selectMatrixForecastParams,
  selectAcCorrespondencesEntity,
  (
    projectListForm,
    pageForecastForm,
    matrixForecastParams,
    forecastCorrespondencesEntities,
  ): [Maybe<Model<AcForecastCorrespondenceModel>[]>, number] => {
    const { selectedProject } = projectListForm;
    const [forecastId] = matrixForecastParams;

    if (!isNumber(selectedProject) || !isNumber(forecastId)) return [Nothing(), 0];

    const filter = getCorrespondencesFilter(pageForecastForm);
    if (isUndefined(filter)) return [Nothing(), 0];

    const forecastCorrespondencesApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      filter,
    };

    const entities = forecastCorrespondencesEntities.getArray(forecastCorrespondencesApiConfig);

    if (!isArray(entities) || !isAcForecastCorrespondenceModel(entities[0])) return [Nothing(), 0];

    const count = forecastCorrespondencesEntities.getCount(forecastCorrespondencesApiConfig);

    return [Just(entities), count];
  },
);

export const selectForecastScenarios = createSelector(
  [
    selectAcProjectListForm,
    selectAcForecastPageForm,
    selectAcForecastScenariosEntity,
  ],
  (projectListForm, pageForecastForm, scenariosEntities): [AcBalanceScenariosModel[], boolean, number] => {
    const { selectedProject } = projectListForm;
    const { year } = pageForecastForm;

    if (!isNumber(selectedProject) || !isNumber(year)) return [[], false, 0];

    const scenariosApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: { year },
    };

    const scenarios = scenariosEntities.getArray(scenariosApiConfig);

    if (!isArray(scenarios) || !isAcScenariosModel(scenarios[0])) return [[], false, 0];

    return [
      scenarios,
      scenariosEntities.getIsLoadingArray(scenariosApiConfig),
      scenariosEntities.getCount(scenariosApiConfig),
    ];
  },
);
