import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import {
  createEntitySelector, createFormSelector, isArray, isNumber,
} from 'src/helpers';
import { Model } from 'src/interfaces';

import {
  EDITOR_GRAPH_FORM_NAME,
  EDITOR_COPY_NETWORK_FORM_NAME,
  AC_PROJECTS_MODEL_NAME,
  AC_SCENARIOS_MODEL_NAME,
  AC_ROUTES_DIRECTIONS_MODEL_NAME,
  AcEditorGraphForm,
  AcScenariosModel,
  AcEditorCopyNetworkForm,
  AcRouteDirectionModel,
  selectAcProjectListForm,
  isAcScenariosModel,
  getApiConfigWithProject,
  isAcRouteDirectionModel,
} from 'src/cluster/common';

export const selectEditorGraphForm = createFormSelector<AcEditorGraphForm>(EDITOR_GRAPH_FORM_NAME);
export const selectEditorCopyNetworkForm = createFormSelector<AcEditorCopyNetworkForm>(EDITOR_COPY_NETWORK_FORM_NAME);
export const selectScenarioEntities = createEntitySelector<AcScenariosModel>(AC_SCENARIOS_MODEL_NAME);
export const selectDirectionEntities = createEntitySelector<AcRouteDirectionModel>(AC_ROUTES_DIRECTIONS_MODEL_NAME);

export const selectEditorScenariosByYear = createSelector(
  [
    selectAcProjectListForm,
    selectEditorGraphForm,
    selectScenarioEntities,
  ],
  (projectsListForm, graphForm, scenarioEntities): Maybe<Model<AcScenariosModel>[]> => {
    const { selectedProject } = projectsListForm;
    const { year } = graphForm;

    if (!isNumber(selectedProject) || !isNumber(year)) {
      return Nothing();
    }

    const scenariosApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        year,
      },
    };

    const scenarios = scenarioEntities.getArray(scenariosApiConfig);

    if (!isArray(scenarios)) {
      return Nothing();
    }

    if (!isAcScenariosModel(scenarios[0])) {
      return Nothing();
    }

    return Just(scenarios);
  },
);

export const selectEditorCopyingNetworkScenariosByYear = createSelector(
  [
    selectAcProjectListForm,
    selectEditorCopyNetworkForm,
    selectScenarioEntities,
  ],
  (projectsListForm, copyingNetworkForm, scenarioEntities): Maybe<Model<AcScenariosModel>[]> => {
    const { selectedProject } = projectsListForm;
    const { year } = copyingNetworkForm;

    if (!isNumber(selectedProject) || !isNumber(year)) {
      return Nothing();
    }

    const scenariosApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        year,
      },
    };

    const scenarios = scenarioEntities.getArray(scenariosApiConfig);

    if (!isArray(scenarios)) {
      return Nothing();
    }

    if (!isAcScenariosModel(scenarios[0])) {
      return Nothing();
    }

    return Just(scenarios);
  },
);

export const selectDirectionsByIds = createSelector(
  [
    selectAcProjectListForm,
    selectEditorCopyNetworkForm,
    selectDirectionEntities,
  ],
  (projectForm, copyingNetworkForm, directionEntities): Maybe<AcRouteDirectionModel[]> => {
    const { selectedProject } = projectForm;
    const { directionIds } = copyingNetworkForm;

    if (!isNumber(selectedProject) || !isArray(directionIds)) {
      return Nothing();
    }

    const filter = {
      id: directionIds,
    };

    const directions = directionEntities.getArray(getApiConfigWithProject(selectedProject, filter));
    const isLoading = directionEntities.getIsLoadingArray(getApiConfigWithProject(selectedProject, filter));

    if (isLoading) {
      return Nothing();
    }

    if (!isArray(directions)) {
      return Nothing();
    }

    if (!isAcRouteDirectionModel(directions[0])) {
      return Nothing();
    }

    return Just(directions);
  },
);
