import {
  isArray, isBoolean, isId, isNumber, isObject, isString,
} from 'src/helpers';
import {
  AcBalanceDataModel,
  AcBehaviorTypesModel,
  AcForecastCorrespondenceModel,
  AcIntervalsModel,
  AcScenariosModel,
  AcSitesModel,
  AcVehicleTypesModel,
  AcYearsModel,
  MatrixForecastModel,
  TtcRegionsModel,
  TtcSitesModel,
  AcStopsModel,
  AcBaseScenarioModel,
  AcVehicleClassesModel,
  AcCommunicationsModel,
  AcTransportationsModel,
  AcCopyRequestModel,
  AcRoutesModel,
  AcRouteDirection,
  PathEdge,
  AcRouteDirectionModel,
} from 'src/cluster/common';

export function isAcVehicleClassesModel(record: unknown): record is AcVehicleClassesModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    name,
    vehicleTypeId,
    area,
    capacity,
    sits,
  } = record as Partial<AcVehicleClassesModel>;

  const isValidModel = isNumber(id)
    && isString(name)
    && isNumber(vehicleTypeId)
    && isNumber(area)
    && isNumber(capacity)
    && isNumber(sits);

  if (!isValidModel) {
    console.warn('The record is not a valid AcVehicleClassesModel.');
  }

  return isValidModel;
}

export function isAcVehicleTypesModel(record: unknown): record is AcVehicleTypesModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    name,
    hasRoutes,
    isPublic,
  } = record as Partial<AcVehicleTypesModel>;

  const isValidModel = isNumber(id)
    && isString(name)
    && isString(name)
    && isBoolean(hasRoutes)
    && isBoolean(isPublic);

  if (!isValidModel) {
    console.warn('The record is not a valid AcVehicleTypesModel.');
  }

  return isValidModel;
}

export function isAcYearsModel(record: unknown): record is AcYearsModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    value,
  } = record as Partial<AcYearsModel>;

  const isValidModel = isNumber(id) && isNumber(value);

  if (!isValidModel) {
    console.warn('The record is not a valid AcYearsModel.');
  }

  return isValidModel;
}

export function isAcScenariosModel(record: unknown): record is AcScenariosModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    name,
  } = record as Partial<AcScenariosModel>;

  const isValidModel = isNumber(id) && isString(name);

  if (!isValidModel) {
    console.warn('The record is not a valid AcScenariosModel.');
  }

  return isValidModel;
}

export function isAcBaseScenariosModel(record: unknown): record is AcBaseScenarioModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    year,
    scenarioId,
  } = record as Partial<AcBaseScenarioModel>;

  const isValidModel = isNumber(year) && isNumber(scenarioId);

  if (!isValidModel) {
    console.warn('The record is not a valid AcBaseScenarioModel.');
  }

  return isValidModel;
}

export function isAcIntervalsModel(record: unknown): record is AcIntervalsModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    intervalId,
    intervalName,
    intervalStart,
    intervalEnd,
  } = record as Partial<AcIntervalsModel>;

  const isValidModel = isNumber(id)
    && isNumber(intervalId)
    && isString(intervalName)
    && isString(intervalStart)
    && isString(intervalEnd);

  if (!isValidModel) {
    console.warn('The record is not a valid AcIntervalsModel.');
  }

  return isValidModel;
}

export function isAcBehaviorTypesModel(record: unknown): record is AcBehaviorTypesModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    name,
  } = record as Partial<AcBehaviorTypesModel>;

  const isValidModel = isNumber(id) && isString(name);

  if (!isValidModel) {
    console.warn('The record is not a valid AcBehaviorTypesModel.');
  }

  return isValidModel;
}

export function isAcSitesModel(record: unknown): record is AcSitesModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    name,
  } = record as Partial<AcSitesModel>;

  const isValidModel = isNumber(id) && isString(name);

  if (!isValidModel) {
    console.warn('The record is not a valid AcSitesModel.');
  }

  return isValidModel;
}

export function isDataModel(record: unknown): record is AcBalanceDataModel {
  if (record === null || typeof record !== 'object') {
    return false;
  }

  const {
    id,
    year,
    source,
  } = record as Partial<AcBalanceDataModel>;

  return isId(id)
    && isNumber(year)
    && isString(source);
}

export function isTtcSitesModel(record: unknown): record is TtcSitesModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    siteFromId,
    siteToId,
  } = record as Partial<TtcSitesModel>;

  const isValidModel = isNumber(id)
    && isNumber(siteFromId)
    && isNumber(siteToId);

  if (!isValidModel) {
    console.warn('The record is not a valid isTtcSitesModel.');
  }

  return isValidModel;
}

export function isTtcRegionsModel(record: unknown): record is TtcRegionsModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    regionFromId,
    regionToId,
  } = record as Partial<TtcRegionsModel>;

  const isValidModel = isNumber(id)
    && isNumber(regionFromId)
    && isNumber(regionToId);

  if (!isValidModel) {
    console.warn('The record is not a valid isTtcRegionsModel.');
  }

  return isValidModel;
}

export function isAcForecastCorrespondenceModel(record: unknown): record is AcForecastCorrespondenceModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    traffic,
  } = record as Partial<AcForecastCorrespondenceModel>;

  const isValidModel = isNumber(id) && isNumber(traffic);

  if (!isValidModel) {
    console.warn('The record is not a valid isAcForecastCorrespondenceModel.');
  }

  return isValidModel;
}

export function isMatrixForecastModel(record: unknown): record is MatrixForecastModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    ttcCalculations,
  } = record as Partial<MatrixForecastModel>;

  const isValidModel = isNumber(id) && isArray(ttcCalculations);

  if (!isValidModel) {
    console.warn('The record is not a valid isMatrixForecastModel.');
  }

  return isValidModel;
}

export function isAcStopModel(record: unknown): record is AcStopsModel {
  if (record === null || typeof record !== 'object') {
    return false;
  }

  const {
    id,
    stopName,
  } = record as Partial<AcStopsModel>;

  const isValidModel = isNumber(id)
    && isString(stopName);

  if (!isValidModel) {
    console.warn('The record is not a valid AcStopsModel.');
  }

  return isValidModel;
}

export function isAcCommunicationsModel(record: unknown): record is AcCommunicationsModel {
  if (!isObject(record)) {
    return false;
  }

  const { id, name } = record as Partial<AcCommunicationsModel>;

  const isValidModel = isNumber(id) && isString(name);

  if (!isValidModel) {
    console.warn('The record is not a valid AcCommunicationsModel.');
  }

  return isValidModel;
}

export function isAcTransportationsModel(record: unknown): record is AcTransportationsModel {
  if (!isObject(record)) {
    return false;
  }

  const { id, name } = record as Partial<AcTransportationsModel>;

  const isValidModel = isNumber(id) && isString(name);

  if (!isValidModel) {
    console.warn('The record is not a valid AcTransportationsModel.');
  }

  return isValidModel;
}

export function isAcCopyRequestsModel(record: unknown): record is AcCopyRequestModel {
  if (!isObject(record)) {
    return false;
  }

  const { id, status, type } = record as Partial<AcCopyRequestModel>;

  const isValidModel = isNumber(id) && isString(status) && isString(type);

  if (!isValidModel) {
    console.warn('The record is not a valid AcCopyRequestModel.');
  }

  return isValidModel;
}

export function isPathEdge(record: unknown): record is PathEdge {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    firstNodeId,
    lastNodeId,
    geometry,
    order,
  } = record as Partial<PathEdge>;

  const isValidModel = isNumber(id)
    && isNumber(firstNodeId)
    && isNumber(lastNodeId)
    && isArray(geometry)
    && isNumber(order);

  if (!isValidModel) {
    console.warn('The record is not a valid PathEdge.');
  }

  return isValidModel;
}

export function isPathEdges(array: unknown): array is PathEdge[] {
  if (!isArray(array)) {
    return false;
  }

  return isPathEdge(array[0]);
}

export function isAcRoutesModel(record: unknown): record is AcRoutesModel {
  if (record === null || typeof record !== 'object') {
    return false;
  }

  const {
    id,
    routeNumber,
    vehicleTypeId,
  } = record as Partial<AcRoutesModel>;

  const isValidModel = isNumber(id)
    && isString(routeNumber)
    && isNumber(vehicleTypeId);

  if (!isValidModel) {
    console.warn('The record is not a valid AcRoutesModel.');
  }

  return isValidModel;
}

export function isAcRouteDirectionModel(record: unknown): record is AcRouteDirectionModel {
  if (record === null || typeof record !== 'object') {
    return false;
  }

  const {
    id,
    direction,
    vehicleTypeId,
    routeNumber,
  } = record as Partial<AcRouteDirectionModel>;

  const isValidModel = isNumber(id)
    && isBoolean(direction)
    && isNumber(vehicleTypeId)
    && isString(routeNumber);

  if (!isValidModel) {
    console.warn('The record is not a valid AcRouteDirectionModel.');
  }

  return isValidModel;
}

export function isAcRouteDirection(record: unknown): record is AcRouteDirection {
  if (record === null || typeof record !== 'object') {
    return false;
  }

  const {
    id,
    direction,
    path,
    nodes,
  } = record as Partial<AcRouteDirection>;

  const isValidModel = isNumber(id)
    && isBoolean(direction)
    && isArray(path)
    && isArray(nodes);

  if (!isValidModel) {
    console.warn('The record is not a valid AcRouteDirection.');
  }

  return isValidModel;
}
