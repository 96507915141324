import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import { isArray } from 'src/helpers';
import modals from 'src/modals';

import { DistFilterModes } from 'src/cluster/common';
import { projectLoadsManager } from 'src/cluster/projects';
import { selectDistGraphForm } from 'src/cluster/distribution-common';
import {
  distPageFormActions,
  AC_DISTRIBUTION_MODAL_NAME,
  AcDistRoutesModel,
  initDistributionTable,
} from 'src/cluster/distribution-table';
import {
  selectAcDistPageForm,
  selectAcProjectPageForm,
  selectDistRoutes,
  selectDistStops,
} from 'src/cluster/distribution-table/store/selectors';

import DistributionTablePage from './DistributionTablePage';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectAcDistPageForm(state),
  graphForm: selectDistGraphForm(state),
  resultsInfo: selectDistRoutes(state),
  resultsStopsInfo: selectDistStops(state),
  projectForm: selectAcProjectPageForm(state),
  isLoading: projectLoadsManager.selectIsDownloadingUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onInit: () => dispatch(initDistributionTable()),
  setFilterMode: (mode: DistFilterModes) => {
    dispatch(distPageFormActions.changeField('filterMode', mode));
  },
  onPageChange: (value: number) => {
    dispatch(distPageFormActions.changeField('currentPage', value));
  },
  onPageSizeChange: (value: number) => {
    dispatch(distPageFormActions.resetField('currentPage'));
    dispatch(distPageFormActions.changeField('pageSize', value));
  },
  showDistributionModal: (record: AcDistRoutesModel) => {
    const updatedRecord = { ...record };
    if (isArray(record.directions)) {
      record.directions.map((dir) => {
        const field = dir.direction ? 'forwardDirectionId' : 'backwardDirectionId';
        return dispatch(distPageFormActions.changeField(field, dir.id));
      });
    }

    dispatch(modals.actions.showModal(true, AC_DISTRIBUTION_MODAL_NAME, {
      record: updatedRecord,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DistributionTablePage));
