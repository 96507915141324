import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { withTranslate } from 'src/i18n';
import { WithModalProps } from 'src/modals';
import { AppState } from 'src/reducer';

import { selectMatrixForecast } from 'src/cluster/common';
import { selectAcForecastPageForm } from 'src/cluster/balance-common';
import { buildForecast } from 'src/cluster/balance-data';

import ModalDataForecast from './ModalDataForecast';

const mapStateToProps = (state: AppState) => ({
  pageForecastForm: selectAcForecastPageForm(state),
  matrixForecastEntities: selectMatrixForecast(state),
});
const mapDispatchToProps = (dispatch: Dispatch<any>, { closeAction }: WithModalProps) => ({
  close: () => dispatch(closeAction),
  buildForecast: (fullRemote: number, buildTTC: boolean) => dispatch(buildForecast(fullRemote, buildTTC)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ModalDataForecast));
