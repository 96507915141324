import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Nullable } from 'src/types';
import { Feature, Point, Position } from 'src/interfaces';
import { featureCollection, isNumber, point } from 'src/helpers';

import { useLegendStopGenerate, AcStopsModel, TrackPoint } from 'src/cluster/common';
import { editorFormActions } from 'src/cluster/editor-common';
import {
  EditorMode,
  PickInfo,
  selectEditorPageForm,
  selectEditorSelectedStop,
  selectEditorStops,
  enableModifyStopMode,
  addPoint,
  createLayer,
  setSelectedStop,
} from 'src/cluster/editor-map';

function replaceWithSelected<T extends AcStopsModel>(selected: Nullable<T>) {
  return (stop: T) => ((selected?.id === stop.id) ? selected : stop);
}

function mapToPoints<T extends AcStopsModel>(stop: T) {
  return point(stop.coordinates, {
    id: stop.id,
    nodeId: stop.nodeId,
    stopName: stop.stopName,
    vehicleTypes: stop.vehicleTypes,
    routeVehicleTypes: stop.routeVehicleTypes,
    communicationTypes: stop.communicationTypes,
    regularTransportationTypes: stop.regularTransportationTypes,
  });
}

const useStopsLayer = (index?: number) => {
  const dispatch = useDispatch();
  const { editorMode, isWaiting } = useSelector(selectEditorPageForm);
  const maybeStops = useSelector(selectEditorStops);
  const selectedStop = useSelector(selectEditorSelectedStop).orNull();

  const handleClick = ({ object }: PickInfo<Feature<Point>>) => {
    dispatch(editorFormActions.changeField('canBePositioned', false));
    const { properties, geometry } = object;
    const stopId = properties?.id || null;
    const nodeId = properties?.nodeId;
    const stopName = properties?.stopName || null;
    const coordinates = geometry?.coordinates as Position;
    if (!isNumber(stopId) || !coordinates) return;
    if (editorMode === EditorMode.addPoint) {
      const node: TrackPoint = {
        nodeId,
        coordinates,
        stopId,
        stopName,
        order: 0,
      };
      dispatch(addPoint(node, index));
    } else if (editorMode !== EditorMode.modifyStop) {
      dispatch(setSelectedStop(stopId));
      if (editorMode === EditorMode.modify) {
        dispatch(enableModifyStopMode());
      }
    }
  };

  const points = useMemo(() => maybeStops.map(
    (stops) => {
      if (!selectedStop) {
        return stops.map(mapToPoints);
      }
      if (selectedStop.id === -1) {
        return stops.concat(selectedStop).map(mapToPoints);
      }
      return stops.map(replaceWithSelected(selectedStop)).map(mapToPoints);
    },
  ).orJust([]), [selectedStop, JSON.stringify(maybeStops)]);

  const id = 'stops';
  const data = featureCollection(points);
  const pickable = !isWaiting && [EditorMode.view, EditorMode.modify, EditorMode.addPoint].includes(editorMode);

  const { getLineColor, getFillColor } = useLegendStopGenerate(selectedStop?.id);

  return createLayer({
    id,
    data,
    pickable,
    getRadius: (feature: Feature) => ((selectedStop && selectedStop?.id === feature?.properties?.id) ? 13 : 10),
    getLineWidth: (feature: Feature) => ((selectedStop && selectedStop?.id === feature?.properties?.id) ? 6 : 3),
    getFillColor,
    getLineColor,
    onClick: handleClick,
  });
};

export default useStopsLayer;
