import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import { Model } from 'src/interfaces';
import { isArray, isNumber } from 'src/helpers';

import {
  AC_PROJECTS_MODEL_NAME,
  selectAcProjectListForm,
  AcRouteDirection,
  AcRoutesModel,
  isAcRoutesModel,
} from 'src/cluster/common';
import { selectEditorGraphForm } from 'src/cluster/editor-common';
import { EMPTY_ROUTE } from 'src/cluster/editor-map';

import {
  selectEditorPageForm,
  selectRouteEntities,
  selectEntitiesEditorForm,
} from './common';

export const selectEditorRoutesByVehicleType = createSelector(
  [
    selectAcProjectListForm,
    selectEditorGraphForm,
    selectEditorPageForm,
    selectRouteEntities,
  ],
  (projectForm, graphForm, pageForm, routesEntities): Maybe<Model<AcRoutesModel>[]> => {
    const { selectedProject } = projectForm;
    const { year, scenarioId } = graphForm;
    const { vehicleTypeId } = pageForm;
    if (!isNumber(selectedProject) || !isNumber(vehicleTypeId) || !isNumber(year) || !isNumber(scenarioId)) {
      return Nothing();
    }

    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        vehicleTypeId,
        year,
        scenarioId,
      },
    };

    const routes = routesEntities.getArray(routeApiConfig);
    const isLoading = routesEntities.getIsLoadingArray(routeApiConfig);

    if (isLoading || !isArray(routes)) {
      return Nothing();
    }

    if (routes.length > 0 && !isAcRoutesModel(routes[0])) {
      return Nothing();
    }

    return Just(routes);
  },
);

export const selectEditorSelectedRoute = createSelector(
  [
    selectAcProjectListForm,
    selectEditorPageForm,
    selectEntitiesEditorForm,
    selectRouteEntities,
  ],
  (projectForm, pageForm, routeEditorForm, routeEntities): Maybe<Model<AcRoutesModel>> => {
    const { editableRoute } = routeEditorForm;

    if (editableRoute) {
      return Just(editableRoute);
    }

    const { selectedProject } = projectForm;

    const {
      routeId, vehicleTypeId = -1,
    } = pageForm;

    if (!isNumber(routeId) || !isNumber(selectedProject)) {
      return Nothing();
    }

    if (routeId === -1) {
      return Just({
        ...EMPTY_ROUTE,
        vehicleTypeId,
      });
    }

    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    const route = routeEntities.getById(routeId, routeApiConfig);

    // FIXME: maybe an issue of redux-restify
    // const isLoading = routeEntities.getIsLoadingById(routeId, routeApiConfig);
    const isLoading = route.$loading;

    if (isLoading || !isAcRoutesModel(route)) {
      return Nothing();
    }

    return Just(route);
  },
);

export const selectEditorSelectedDirection = createSelector(
  [
    selectEditorSelectedRoute,
    selectEditorPageForm,
  ],
  (selectedRoute, pageForm): Maybe<AcRouteDirection> => {
    const { directionId } = pageForm;

    if (!isNumber(directionId)) {
      return Nothing();
    }

    return selectedRoute.flatMap((route) => {
      const direction = route.variants
        .flatMap((variant) => variant.routeDirections)
        .find(routeDirection => routeDirection.id === directionId);
      return direction ? Just(direction) : Nothing();
    });
  },
);
