import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
  Fragment,
} from 'react';

import { Nullable } from 'src/types';
import { WithTranslate } from 'src/i18n';
import { AutocompleteOption, Model } from 'src/interfaces';
import { UNITS } from 'src/constants';

import { DropDownValue } from 'src/components/deprecated/PSelect'; // FIXME
import ArrowRightAndLeftIcon from 'src/components/icons/ArrowRightAndLeftIcon'; // FIXME

import {
  NumberFormat,
  WithUnit,
  Toolbar,
  ToolbarBlock,
  ToolbarControl,
  ToolbarControls,
  Autocomplete,
  SelectField,
  CollapseWithToggle,
  ExcelIcon,
  Button, CircularProgressWrapper,
} from 'src/components';

import {
  useAcYearOptions,
  setMaxValue,
  useAcIntervalOptions,
  AcBalanceForecastForm,
  AcBalanceTrafficSumModel,
  useAcNodesOptions,
  useAcMunicipalitiesOptions,
  useAcFilterModesOptions,
  hasToResetSelect,
  setDefaultValue,
  BalanceFilterModes,
  MatrixForecastModel,
  MatrixForecastProgress,
} from 'src/cluster/common';
import { useScenarios } from 'src/cluster/balance-forecast';

import useStyles from './styles';

interface Props extends WithTranslate {
  pageForm: AcBalanceForecastForm;
  totalTraffic?: AcBalanceTrafficSumModel;
  matrixForecastEntities: [Nullable<Model<MatrixForecastModel>>, boolean];
  matrixForecastParams: [number | undefined, number[] | undefined];
  setFilterModeFrom(event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void;
  setFilterModeTo(event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void;
  setRegionFrom([value]: DropDownValue[]): void;
  setRegionTo(values: DropDownValue[]): void;
  setMunicipalityFrom(values: DropDownValue[]): void;
  setMunicipalityTo(values: DropDownValue[]): void;
  setScenario(event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void;
  setYear(event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void;
  setInterval(event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void;
  handleSwitch(): void;
  showExportsModal(): void;
}

const ForecastCorrespondencesToolbar: FC<Props> = (props) => {
  const {
    matrixForecastEntities: [matrixForecast],
    matrixForecastParams: [forecastId],
    pageForm,
    totalTraffic,
    locale,
    t,
    setFilterModeFrom,
    setFilterModeTo,
    setRegionFrom,
    setRegionTo,
    setMunicipalityFrom,
    setMunicipalityTo,
    setScenario,
    setYear,
    setInterval,
    handleSwitch,
    showExportsModal,
  } = props;

  const {
    filterModeFrom,
    filterModeTo,
    regionFrom = [],
    regionTo = [],
    municipalityFrom = [],
    municipalityTo = [],
    scenario,
    year,
    interval,
    isExporting,
  } = pageForm;

  const classes = useStyles();

  const fullRemote = matrixForecast ? matrixForecast.remoteJobsPercent * 100 : matrixForecast;

  const nodesItems = useAcNodesOptions();
  const municipalitiesItems = useAcMunicipalitiesOptions();
  const modes = useAcFilterModesOptions();
  const yearsItems = useAcYearOptions();
  const scenariosItems = useScenarios();
  const intervalOptions = useAcIntervalOptions();

  const selectedRegionsFrom = regionFrom ? nodesItems.filter(item => regionFrom.includes(Number(item.value))) : [];

  const selectedRegionsTo = regionTo ? nodesItems.filter(item => regionTo.includes(Number(item.value))) : [];

  const selectedMunicipalityFrom = municipalityFrom ?
    municipalitiesItems.filter(item => municipalityFrom.includes(Number(item.value))) : [];

  const selectedMunicipalityTo = municipalityTo ?
    municipalitiesItems.filter(item => municipalityTo.includes(Number(item.value))) : [];

  const [baseYearChosen, setBaseYearChosen] = useState(false);

  // set default year filter
  useEffect(() => {
    if (hasToResetSelect(yearsItems, Number(year))) {
      setMaxValue(setYear, yearsItems);
    }
  }, [yearsItems]);

  // set default scenario filter
  useEffect(() => {
    if (hasToResetSelect(scenariosItems, Number(scenario))) {
      setDefaultValue(setScenario, scenariosItems);
    }
  }, [scenariosItems, year]);

  useEffect(() => {
    const scenarioItem = scenariosItems.length > 0 && scenariosItems.filter(item => item.value === scenario)[0];
    if (scenarioItem && scenarioItem.label === 'Базовый год') {
      setBaseYearChosen(true);
    } else {
      setBaseYearChosen(false);
    }
  }, [scenario, scenariosItems]);

  // set default interval filter
  useEffect(() => {
    if (hasToResetSelect(intervalOptions, Number(interval))) {
      setDefaultValue(setInterval, intervalOptions);
    }
  }, [intervalOptions]);

  const handleRegionFromChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setRegionFrom(values.map(item => item.value as number));
    } else {
      setRegionFrom([]);
    }
  }, [regionFrom]);

  const handleRegionToChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setRegionTo(values.map(item => item.value as number));
    } else {
      setRegionTo([]);
    }
  }, [regionTo]);

  const handleMunicipalityFromChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setMunicipalityFrom(values.map(item => item.value as number));
    } else {
      setMunicipalityFrom([]);
    }
  }, [municipalityFrom]);

  const handleMunicipalityToChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setMunicipalityTo(values.map(item => item.value as number));
    } else {
      setMunicipalityTo([]);
    }
  }, [municipalityTo]);

  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(value => !value);

  const buttonsGroup = (
    <CircularProgressWrapper loading={isExporting}>
      <Button
        startIcon={
          <span className={classes.iconButtonRed}>
            <ExcelIcon />
          </span>
        }
        className={classes.link}
        variant="text"
        disabled={isExporting || !forecastId}
        onClick={showExportsModal}
      >
        {t('common.captions.export')}
      </Button>
    </CircularProgressWrapper>
  );

  return (
    <div className={classes.projectCreateContainer}>
      <CollapseWithToggle
        collapsed={collapsed}
        toggle={toggle}
        progress={<MatrixForecastProgress />}
        buttonsGroup={buttonsGroup}
        caption={t('systems.matrix.captions.filter')}
      >
        <Toolbar className={classes.root}>
          <ToolbarBlock className={classes.block}>
            <ToolbarControls className={classes.toolbarControls} position="top">
              <ToolbarControl>
                <SelectField
                  label={t('common.captions.year')}
                  options={yearsItems}
                  value={year}
                  fullWidth
                  onChange={setYear}
                  className={classes.tightSelect}
                />
              </ToolbarControl>
              <ToolbarControl>
                <SelectField
                  label={t('common.captions.scenario')}
                  options={scenariosItems}
                  value={scenario}
                  fullWidth
                  onChange={setScenario}
                  className={classes.select}
                />
              </ToolbarControl>
              <ToolbarControl>
                <SelectField
                  className={classes.select}
                  label={t('common.captions.interval')}
                  options={intervalOptions}
                  value={interval}
                  fullWidth
                  sort={false}
                  onChange={setInterval}
                />
              </ToolbarControl>
              <ToolbarControl className={classes.traffic}>
                <div className={classes.total}>
                  <div>
                    {t('systems.balance.captions.baseYearPassengerTrafficTotal')}
                    {': '}
                    <span className={classes.number}>
                      {totalTraffic ?
                        (
                          <WithUnit unit={t(UNITS.people)}>
                            <NumberFormat
                              value={totalTraffic.trafficBaseYearTotal || totalTraffic.trafficForecastTotal}
                              locale={locale}
                              decimalScale={0}
                            />
                          </WithUnit>
                        )
                        : '...'}
                    </span>
                  </div>
                  <div>
                    {t(`systems.balance.captions.${baseYearChosen ? 'baseYearPassengerTrafficOT' : 'forecastYearPassengerTrafficPublic'}`)}
                    {': '}
                    <span className={classes.number}>
                      {totalTraffic ? (
                        <WithUnit unit={t(UNITS.people)}>
                          <NumberFormat
                            value={totalTraffic.trafficForecastPublic}
                            locale={locale}
                            decimalScale={0}
                          />
                        </WithUnit>
                      ) : '...'}
                    </span>
                  </div>
                  <div>
                    {t(`systems.balance.captions.${baseYearChosen ? 'baseYearPassengerTrafficLT' : 'forecastYearPassengerTrafficPrivate'}`)}
                    {': '}
                    <span className={classes.number}>
                      {totalTraffic ? (
                        <WithUnit unit={t(UNITS.people)}>
                          <NumberFormat
                            value={totalTraffic.trafficForecastPrivate}
                            locale={locale}
                            decimalScale={0}
                          />
                        </WithUnit>
                      ) : '...'}
                    </span>
                  </div>
                  {!baseYearChosen && (
                    <>
                      <div>
                        {t('systems.balance.captions.forecastYearPassengerTrafficTotal')}
                        {': '}
                        <span className={classes.number}>
                          {totalTraffic ? (
                            <WithUnit unit={t(UNITS.people)}>
                              <NumberFormat
                                value={totalTraffic.trafficBaseYearTotal && totalTraffic.trafficForecastTotal}
                                locale={locale}
                                decimalScale={0}
                              />
                            </WithUnit>
                          ) : '...'}
                        </span>
                      </div>
                      <div>
                        {t('systems.balance.captions.increaseInPassengerTraffic')}
                        {': '}
                        <span className={classes.number}>
                          {totalTraffic ? (
                            <WithUnit unit="%">
                              <NumberFormat
                                value={(totalTraffic.trafficIncrease ?? 0) * 100}

                                locale={locale}
                                decimalScale={0}
                              />
                            </WithUnit>
                          ) : '...'}
                        </span>
                      </div>
                      <div>
                        {t('systems.balance.captions.fullRemote')}
                        {': '}
                        <span className={classes.number}>
                          {fullRemote ? (
                            <Fragment>
                              <NumberFormat
                                value={fullRemote}
                                locale={locale}
                                decimalScale={0}
                              />
                              %
                            </Fragment>
                          ) : '...'}
                        </span>
                      </div>
                    </>)}
                </div>
              </ToolbarControl>
            </ToolbarControls>
            <ToolbarControls className={classes.toolbarControls} position="top">
              <ToolbarControl>
                <SelectField
                  label={t('systems.balance.captions.territorialCell')}
                  options={modes}
                  value={filterModeFrom}
                  fullWidth
                  onChange={setFilterModeFrom}
                  className={classes.tightSelect}
                />
              </ToolbarControl>

              <ToolbarControl>
                {filterModeFrom === BalanceFilterModes.node ? (
                  <Autocomplete
                    multiple
                    label={t('common.captions.from')}
                    placeholder={t('components.captions.select')}
                    limitTags={8}
                    options={nodesItems}
                    selected={selectedRegionsFrom}
                    onChange={handleRegionFromChange}
                    className={classes.multiSelect}
                  />
                ) : (
                  <Autocomplete
                    multiple
                    label={t('common.captions.from')}
                    placeholder={t('components.captions.select')}
                    limitTags={8}
                    options={municipalitiesItems}
                    selected={selectedMunicipalityFrom}
                    onChange={handleMunicipalityFromChange}
                    className={classes.multiSelect}
                  />
                )}
              </ToolbarControl>
              <ToolbarControl >
                <span className={classes.arrows}>
                  <Button variant="icon" onClick={handleSwitch}>
                    <ArrowRightAndLeftIcon />
                  </Button>
                </span>
              </ToolbarControl>
              <ToolbarControl>
                <SelectField
                  label={t('systems.balance.captions.territorialCell')}
                  options={modes}
                  value={filterModeTo}
                  fullWidth
                  onChange={setFilterModeTo}
                  className={classes.select}
                />
              </ToolbarControl>
              <ToolbarControl>
                {filterModeTo === BalanceFilterModes.node ? (
                  <Autocomplete
                    multiple
                    label={t('common.captions.to')}
                    placeholder={t('components.captions.select')}
                    limitTags={8}
                    options={nodesItems}
                    selected={selectedRegionsTo}
                    onChange={handleRegionToChange}
                    className={classes.multiSelect}
                  />
                ) : (
                  <Autocomplete
                    multiple
                    label={t('common.captions.to')}
                    placeholder={t('components.captions.select')}
                    limitTags={8}
                    options={municipalitiesItems}
                    selected={selectedMunicipalityTo}
                    onChange={handleMunicipalityToChange}
                    className={classes.multiSelect}
                  />
                )}
              </ToolbarControl>
            </ToolbarControls>
          </ToolbarBlock>
        </Toolbar>
      </CollapseWithToggle>
    </div>
  );
};

export default ForecastCorrespondencesToolbar;
