import { MODAL_SIZES, registerModal } from 'src/modals';

import { AppState } from 'src/reducer';
import { AC_DATA_FORECAST_MODAL_NAME, ModalDataForecast } from 'src/cluster/balance-data';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.small,
  };
};

export default registerModal(
  AC_DATA_FORECAST_MODAL_NAME,
  stateToProps,
)(ModalDataForecast);
