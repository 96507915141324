import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import { Nullable, ProcessStatus } from 'src/types';
import { Model } from 'src/interfaces';
import {
  createEndpointSelector, createEntitySelector, createFormSelector, isArray, isNumber,
} from 'src/helpers';

import {
  AC_PROJECTS_MODEL_NAME,
  AC_MAP_LEGEND_FORM_NAME,
  AC_MATRIX_FORECAST_INFO_MODEL_NAME as FORECAST_INFO_MODEL_NAME,
  AC_MUNICIPALITIES_MODEL_NAME,
  AC_NODES_MODEL_NAME,
  AC_GEOJSON_FORM_NAME,
  AC_BASE_SCENARIO_MODEL_NAME,
  AC_PROJECT_LIST_FORM_NAME,
  AC_VEHICLE_CLASSES_MODEL_NAME,
  AC_VEHICLE_TYPES_MODEL_NAME,
  AC_YEARS_MODEL_NAME,
  AC_SCENARIOS_MODEL_NAME,
  AC_INTERVALS_MODEL_NAME,
  AC_BEHAVIOR_TYPES_MODEL_NAME,
  AC_SITES_MODEL_NAME,
  AC_COMMUNICATION_TYPES_MODEL_NAME as COMMUNICATION_TYPES_MODEL_NAME,
  AC_REGULAR_TRANSPORTATION_TYPES_MODEL_NAME as TRANSPORT_TYPES_MODEL_NAME,
  AC_COPY_REQUESTS_MODEL_NAME,
  AcScenariosModel,
  AcIntervalsModel,
  AcBehaviorTypesModel,
  AcVehicleClassesModel,
  AcVehicleTypesModel,
  AcYearsModel,
  AcSitesModel,
  AcCommunicationsModel,
  AcTransportationsModel,
  AcProjectListForm,
  AcGeojsonForm,
  AcEditorLegendForm,
  AcMunicipalitiesModel,
  AcNodeModel,
  MatrixForecastModel,
  AcBaseScenarioModel,
  AcCopyRequestModel,
  isMatrixForecastModel,
  isAcBaseScenariosModel,
} from 'src/cluster/common';

export const selectProjectEndpoint = createEndpointSelector(AC_PROJECTS_MODEL_NAME);

export const selectAcProjectListForm = createFormSelector<AcProjectListForm>(AC_PROJECT_LIST_FORM_NAME);
export const selectGeojsonForm = createFormSelector<AcGeojsonForm>(AC_GEOJSON_FORM_NAME);
export const selectEditorLegendForm = createFormSelector<AcEditorLegendForm>(AC_MAP_LEGEND_FORM_NAME);

export const selectVehicleClassesEntities = createEntitySelector<AcVehicleClassesModel>(AC_VEHICLE_CLASSES_MODEL_NAME);
export const selectVehicleTypeEntities = createEntitySelector<AcVehicleTypesModel>(AC_VEHICLE_TYPES_MODEL_NAME);
export const selectYearEntities = createEntitySelector<AcYearsModel>(AC_YEARS_MODEL_NAME);
export const selectScenarioEntities = createEntitySelector<AcScenariosModel>(AC_SCENARIOS_MODEL_NAME);
export const selectIntervalEntities = createEntitySelector<AcIntervalsModel>(AC_INTERVALS_MODEL_NAME);
export const selectBehaviorTypeEntities = createEntitySelector<AcBehaviorTypesModel>(AC_BEHAVIOR_TYPES_MODEL_NAME);
export const selectSiteEntities = createEntitySelector<AcSitesModel>(AC_SITES_MODEL_NAME);
export const selectCommunicationEntities = createEntitySelector<AcCommunicationsModel>(COMMUNICATION_TYPES_MODEL_NAME);
export const selectTransportationEntities = createEntitySelector<AcTransportationsModel>(TRANSPORT_TYPES_MODEL_NAME);

export const selectNodeEntities = createEntitySelector<AcNodeModel>(AC_NODES_MODEL_NAME);
export const selectMunicipalityEntities = createEntitySelector<AcMunicipalitiesModel>(AC_MUNICIPALITIES_MODEL_NAME);
export const selectBaseScenarioEntities = createEntitySelector<AcBaseScenarioModel>(AC_BASE_SCENARIO_MODEL_NAME);

export const selectMatrixForecastEntities = createEntitySelector<MatrixForecastModel>(FORECAST_INFO_MODEL_NAME);

export const selectCopyRequestEntities = createEntitySelector<AcCopyRequestModel>(AC_COPY_REQUESTS_MODEL_NAME);

export const selectMatrixForecast = createSelector(
  [
    selectAcProjectListForm,
    selectMatrixForecastEntities,
  ],
  (projectsListForm, matrixForecastEntities): [Nullable<Model<MatrixForecastModel>>, boolean] => {
    const { selectedProject } = projectsListForm;
    if (!isNumber(selectedProject)) {
      return [null, false];
    }

    const matrixForecastApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: { pageSize: 1 },
    };

    const matrixForecast = matrixForecastEntities.getArray(matrixForecastApiConfig);
    const isLoadingArray = matrixForecastEntities.getIsLoadingArray(matrixForecastApiConfig);

    if (
      isLoadingArray
      || !isArray(matrixForecast)
      || !isMatrixForecastModel(matrixForecast[0])
    ) return [null, isLoadingArray];

    return [matrixForecast[0], false];
  },
);

export const selectMatrixForecastParams = createSelector(
  selectMatrixForecast,
  ([matrixForecast, isLoading]): [number | undefined, number[] | undefined] => {
    if (!isMatrixForecastModel(matrixForecast)) {
      return [undefined, undefined];
    }

    if (isLoading || matrixForecast.status !== ProcessStatus.SUCCESS) {
      return [undefined, undefined];
    }

    return [matrixForecast.id, matrixForecast.ttcCalculations];
  },
);

export const selectAcBaseScenario = createSelector(
  [
    selectAcProjectListForm,
    selectBaseScenarioEntities,
  ],
  (projectsListForm, baseScenarioEntities): Maybe<Model<AcBaseScenarioModel>> => {
    const { selectedProject } = projectsListForm;

    if (!isNumber(selectedProject)) {
      return Nothing();
    }

    const scenariosApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    const baseScenario = baseScenarioEntities.getById('', scenariosApiConfig);
    const isBaseScenarioLoading = baseScenarioEntities.getIsLoadingById('', scenariosApiConfig);

    if (isBaseScenarioLoading || !isAcBaseScenariosModel(baseScenario)) {
      return Nothing();
    }

    return Just(baseScenario);
  },
);

export const selectCurrentProjectNodes = createSelector(
  [
    selectAcProjectListForm,
    selectNodeEntities,
  ],
  (pageProjectsForm, nodesEntities): [AcNodeModel[], boolean] => {
    if (!pageProjectsForm.selectedProject) {
      return [
        [],
        false,
      ];
    }
    const nodesApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
    };
    return [
      nodesEntities.getArray(nodesApiConfig),
      nodesEntities.getIsLoadingArray(nodesApiConfig),
    ];
  },
);

export const selectCurrentProjectMunicipalities = createSelector(
  [
    selectAcProjectListForm,
    selectMunicipalityEntities,
  ],
  (pageProjectsForm, nodesEntities): [AcMunicipalitiesModel[], boolean] => {
    if (!pageProjectsForm.selectedProject) {
      return [
        [],
        false,
      ];
    }
    const municipalitiesApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
    };
    return [
      nodesEntities.getArray(municipalitiesApiConfig),
      nodesEntities.getIsLoadingArray(municipalitiesApiConfig),
    ];
  },
);
