import React, {
  ChangeEvent, FC, useEffect, useState,
} from 'react';
import { Typography } from '@material-ui/core';

import { Nullable } from 'src/types';
import { WithTranslate } from 'src/i18n';
import { Model } from 'src/interfaces';
import { Button, Slider, FormControlLabel } from 'src/components';
import { AcBalanceForecastForm, MatrixForecastModel } from 'src/cluster/common';

import useStyles from './styles';

interface Props extends WithTranslate {
  pageForecastForm: AcBalanceForecastForm;
  matrixForecastEntities: [Nullable<Model<MatrixForecastModel>>, boolean];
  buildForecast(fullRemote: number, buildTTC: boolean): void;
  setFullRemote(value: number): void;
  close: () => void;
}

const ModalDataForecast: FC<Props> = (props) => {
  const {
    t,
    matrixForecastEntities: [matrixForecast, isLoading],
    close,
    buildForecast,
  } = props;

  const classes = useStyles();
  const [buildTTC, setBuildTTC] = useState(true);
  const [fullRemote, setFullRemote] = useState(0);

  const disabled = isLoading || !matrixForecast?.ttcCalculationsSuccess;

  useEffect(() => {
    if (disabled) {
      setBuildTTC(true);
    }
  }, [disabled]);

  const handleBuild = () => {
    buildForecast(fullRemote, buildTTC);
    close();
  };

  const handleChange = (event: ChangeEvent<{}>, checked: boolean) => {
    setBuildTTC(checked);
  };
  return (
    <div className={classes.root}>
      <Typography variant="h5">{t('systems.matrix.captions.forecastOptions')}</Typography>
      <Typography className={classes.subTitle}>{t('systems.matrix.messages.buildPassengerTraffic')}</Typography>

      <div className={classes.fields}>
        <div className={classes.field}>
          <Slider
            className={classes.slider}
            name="fullRemote"
            onChange={setFullRemote}
            label={`${t('systems.balance.captions.fullRemote')}, %`}
            max={100}
            value={fullRemote}
          />
        </div>
        <div className={classes.field}>
          <FormControlLabel
            checked={buildTTC}
            disabled={disabled}
            label={t('modules.ttcCalculations.captions.recalculateSTZ')}
            onChange={handleChange}
          />
        </div>

      </div>
      <div className={classes.buttons}>
        <Button
          className={classes.button}
          disabled={isLoading}
          onClick={handleBuild}
        >
          {t('modules.forecast.captions.buildForecast')}
        </Button>
      </div>
    </div>
  );
};

export default ModalDataForecast;
