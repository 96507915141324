import React, { FC } from 'react';
import clsx from 'clsx';
import { uniq } from 'lodash';
import { Divider, Grid } from '@material-ui/core';

import { Model } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';
import { Button } from 'src/components';

import { SubmitButtons, AcRoutesModel } from 'src/cluster/common';
import {
  EditorRouteTreeView, useVehicleTypeName,
} from 'src/cluster/editor-map';

import { RouteHeader } from './components';

import useStyles, { useStylesGrid } from './styles';

interface OwnProps {
  isLoading: boolean;
  isModifying: boolean;
  isDirty: boolean;
  selectedRoute: Model<AcRoutesModel>;
  selectedNodeId?: number;
  enableModifyMode(): void;
  enableViewMode(): void;
  saveRoute(create: boolean): void;
  copyRoute(): void;
  deleteRoute(id: number, num: string): void;
  addVariant(): void;
  changeRouteNumber(value: string): void;
}

type Props = OwnProps & WithTranslate;

const EditorRoute: FC<Props> = (props) => {
  const {
    isLoading,
    isModifying,
    isDirty,
    selectedRoute,
    selectedNodeId,
    t,
    enableModifyMode,
    enableViewMode,
    saveRoute,
    copyRoute,
    deleteRoute,
    addVariant,
    changeRouteNumber,
  } = props;

  const {
    routeNumber, vehicleTypeId, variants, id,
  } = selectedRoute;

  const classes = useStyles();
  const gridClasses = useStylesGrid();
  const getVehicleTypeName = useVehicleTypeName();
  const vehicleTypeName = getVehicleTypeName(vehicleTypeId);

  const hasNewVariant = variants.some(v => v.id === -1);

  const hasRouteError = routeNumber === '';
  const hasVariantError = variants.some(variant => variant.variantNumber === '')
    || variants.length > uniq(variants.map(v => v.variantNumber)).length;
  const hasError = isModifying && (hasRouteError || hasVariantError);

  const handleSaveRoute = async () => {
    saveRoute(id < 0);
  };

  return (
    <div className={clsx(classes.root, isModifying && classes.editing)}>
      <RouteHeader
        isLoading={isLoading}
        isModifying={isModifying}
        id={id}
        routeNumber={routeNumber}
        vehicleTypeName={vehicleTypeName}
        hasError={isDirty && hasRouteError}
        copyRoute={copyRoute}
        deleteRoute={deleteRoute}
        enableModifyMode={enableModifyMode}
        changeRouteNumber={changeRouteNumber}
      />
      <div className={classes.tree}>
        <EditorRouteTreeView
          selectedNodeId={selectedNodeId}
          variants={variants}
          disableSelection={isLoading}
          vehicleTypeName={vehicleTypeName}
        />
      </div>
      {isModifying && (
        <div className={classes.footer}>
          <Divider />
          <Grid classes={gridClasses}>
            <Button
              variant="link"
              className={classes.button}
              disabled={hasNewVariant}
              onClick={addVariant}
            >
              {t('modules.editor.captions.toAddVariant')}
            </Button>
          </Grid>
          <Divider />
          <SubmitButtons
            className={classes.submitButtons}
            isLoading={isLoading}
            disabled={hasError}
            onCancel={enableViewMode}
            onSubmit={handleSaveRoute}
          />
        </div>
      )}
    </div>
  );
};

export default EditorRoute;
