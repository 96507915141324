import { AcDistGraphForm } from 'src/cluster/common';

const defaults: AcDistGraphForm = {
  year: undefined,
  scenarioId: undefined,
  intervalId: undefined,
  day: false,
};

export default { defaults };
