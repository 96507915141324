import React, {
  ChangeEvent, FC, useEffect, useState,
} from 'react';
import { ButtonGroup } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { AutocompleteOption, SelectMenuOption } from 'src/interfaces';
import {
  SelectField,
  Toolbar,
  ToolbarBlock,
  ToolbarControl,
  ToolbarControls,
  CollapseWithToggle,
  ExcelIcon,
  Autocomplete,
  Button,
  CircularProgressWrapper,
} from 'src/components';
import {
  useAcIntervalOptions,
  useAcSiteOptions,
  useAcNodesOptions,
  useAcYearOptions,
  TtcCalcPageForm,
  hasToResetSelect,
  setMaxValue,
  setDefaultValue,
  MatrixForecastProgress,
} from 'src/cluster/common';
import {
  useTtcScenarioOptions,
  AcTtcCalcPageFormEnum,
} from 'src/cluster/balance-ttc';
import { isString } from 'src/helpers';

import useStyles from './styles';

interface Props extends WithTranslate{
  pageForm: TtcCalcPageForm;
  matrixForecastParams: [number | undefined, number[] | undefined];
  showExportsModal(): void;
  setYear(event: ChangeEvent<{ value: unknown }>): void;
  setScenario(event: ChangeEvent<{ value: unknown }>): void;
  setInterval(event: ChangeEvent<{ value: unknown }>): void;
  onChangeField(field: AcTtcCalcPageFormEnum, value?: number | boolean): void;
}

const TtcCalcToolbar: FC<Props> = (props) => {
  const {
    pageForm: {
      year,
      scenarioId,
      intervalId,
      siteFromId,
      siteToId,
      regionFromId,
      regionToId,
      isSites,
      isExporting,
      progress,
    },
    matrixForecastParams: [forecastId],
    showExportsModal,
    setYear,
    setScenario,
    setInterval,
    onChangeField,
    t,
  } = props;

  const classes = useStyles();

  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(value => !value);

  const scenariosItems = useTtcScenarioOptions();
  const yearsItems = useAcYearOptions();
  const intervalOptions = useAcIntervalOptions();
  const intervalItems = useAcIntervalOptions();

  const siteOptions = useAcSiteOptions();
  const regionOptions = useAcNodesOptions();
  const regionFromIdValue = regionOptions.find(opt => opt.value === regionFromId);
  const regionToIdValue = regionOptions.find(opt => opt.value === regionToId);
  const siteFromIdValue = siteOptions.find(opt => opt.value === siteFromId);
  const siteToIdValue = siteOptions.find(opt => opt.value === siteToId);
  // set default year filter
  useEffect(() => {
    if (hasToResetSelect(yearsItems, Number(year))) {
      setMaxValue(setYear, yearsItems);
    }
  }, [yearsItems]);

  // set default scenario filter
  useEffect(() => {
    if (hasToResetSelect(scenariosItems, Number(scenarioId))) {
      setDefaultValue(setScenario, scenariosItems);
    }
  }, [scenariosItems, year]);

  // set default interval filter
  useEffect(() => {
    if (hasToResetSelect(intervalItems, Number(intervalId))) {
      setDefaultValue(setInterval, intervalItems);
    }
  }, [intervalItems]);

  const handleSetDeparture = (val: AutocompleteOption) => {
    let { value } = val as SelectMenuOption || {};
    const field = isSites ? AcTtcCalcPageFormEnum.siteFromId : AcTtcCalcPageFormEnum.regionFromId;
    if (isString(value)) value = Number(value);
    onChangeField(field, value);
  };

  const handleSetDestination = (val: AutocompleteOption) => {
    let { value } = val as SelectMenuOption || {};
    const field = isSites ? AcTtcCalcPageFormEnum.siteToId : AcTtcCalcPageFormEnum.regionToId;
    if (isString(value)) value = Number(value);
    onChangeField(field, value);
  };

  const handleToggle = () => {
    onChangeField(AcTtcCalcPageFormEnum.isSites, !isSites);
  };
  const progressValue = progress || 0;

  const buttonsGroup = (
    <CircularProgressWrapper
      loading={isExporting}
      size={50}
      thickness={5}
      marginLeft="-12px"
      value={progressValue * 100}
    >
      <Button
        startIcon={
          <span className={classes.iconButtonRed}>
            <ExcelIcon />
          </span>
        }
        className={classes.link}
        variant="text"
        onClick={showExportsModal}
        disabled={isExporting || !forecastId}
      >
        {t('common.captions.export')}
      </Button>
    </CircularProgressWrapper>
  );

  return (
    <div className={classes.projectCreateContainer}>
      <CollapseWithToggle
        collapsed={collapsed}
        toggle={toggle}
        progress={<MatrixForecastProgress />}
        buttonsGroup={buttonsGroup}
        caption={t('modules.ttc.captions.totalTransportCosts')}
      >
        <Toolbar className={classes.root}>
          <div className={classes.toggle}>
            <ButtonGroup
              className={classes.btnGroup}
              color="primary"
              size="small"
              variant="contained"
              onClick={handleToggle}
            >
              <Button
                variant={isSites ? 'contained' : 'outlined'}
                className={isSites ? classes.iconButton : classes.iconButtonWhite}
              >
                {t('modules.ttcCalculations.captions.sites')}
              </Button>
              <Button
                variant={!isSites ? 'contained' : 'outlined'}
                className={!isSites ? classes.iconButton : classes.iconButtonWhite}
              >
                {t('modules.ttcCalculations.captions.districts')}
              </Button>
            </ButtonGroup>
          </div>
          <ToolbarBlock
            size="large"
          >
            <ToolbarControls position="top" className={classes.toolbarControls}>
              <ToolbarControl>
                <SelectField
                  className={classes.wideSelect}
                  label={t('common.captions.year')}
                  options={yearsItems}
                  value={year}
                  fullWidth
                  onChange={setYear}
                />
              </ToolbarControl>
              <ToolbarControl>
                <SelectField
                  className={classes.wideSelect}
                  label={t('common.captions.scenario')}
                  options={scenariosItems}
                  value={scenarioId}
                  fullWidth
                  onChange={setScenario}
                />
              </ToolbarControl>
              <ToolbarControl>
                <SelectField
                  className={classes.wideSelect}
                  label={t('common.captions.interval')}
                  options={intervalOptions}
                  value={intervalId}
                  fullWidth
                  sort={false}
                  onChange={setInterval}
                />
              </ToolbarControl>
            </ToolbarControls>

            {isSites ? (
              <ToolbarControls position="bottom" className={classes.toolbarControls}>
                <ToolbarControl>
                  <Autocomplete
                    className={classes.wideSelect}
                    placeholder={t('components.captions.select')}
                    multiple={false}
                    label={t('modules.ttcCalculations.captions.siteOfDeparture')}
                    selected={siteFromIdValue}
                    options={siteOptions}
                    onChange={handleSetDeparture}
                  />
                </ToolbarControl>
                <ToolbarControl>
                  <Autocomplete
                    className={classes.wideSelect}
                    placeholder={t('components.captions.select')}
                    multiple={false}
                    label={t('modules.ttcCalculations.captions.siteOfDestination')}
                    selected={siteToIdValue}
                    options={siteOptions}
                    onChange={handleSetDestination}
                  />
                </ToolbarControl>
              </ToolbarControls>
            ) : (
              <ToolbarControls position="bottom" className={classes.toolbarControls}>
                <ToolbarControl>
                  <Autocomplete
                    className={classes.wideSelect}
                    placeholder={t('components.captions.select')}
                    multiple={false}
                    label={t('modules.ttcCalculations.captions.firstDistrict')}
                    selected={regionFromIdValue}
                    options={regionOptions}
                    onChange={handleSetDeparture}
                  />
                </ToolbarControl>
                <ToolbarControl>
                  <Autocomplete
                    className={classes.wideSelect}
                    placeholder={t('components.captions.select')}
                    multiple={false}
                    label={t('modules.ttcCalculations.captions.secondDistrict')}
                    selected={regionToIdValue}
                    options={regionOptions}
                    onChange={handleSetDestination}
                  />
                </ToolbarControl>
              </ToolbarControls>
            )}
          </ToolbarBlock>
        </Toolbar>
      </CollapseWithToggle>
    </div>
  );
};

export default TtcCalcToolbar;
